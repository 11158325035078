//font
$DINPro:'DINPro';
$DINCBold:'din_condensedbold';
// $DINCBold:'DINCondensed-Bold';

// Colors
$light:#fff;
$light-blue:#569EBE;
$dark-blue:#042F5B;
$dark-blue2:#123150;

//Others
$site-width: 1440px;
