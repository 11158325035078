$shape-color: #fff;
$hero-width: 960px;
$hero-height: 540px;



.svg-hero{
  position: relative;
  margin: 20px 0 200px;

  @include media(x-small){
      margin: 50px 0 200px;
  }


  .tagline{
    text-align: center;
    background: $dark-blue2;
    margin: auto;
    width: 784px;
    padding: 6px 0;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 30px;



    h2{
      color: $light;
      font-family: $DINCBold;
      font-size: 27px;
      margin: 0;
      padding: 2px 0;
      letter-spacing: 1px;

      @include media(x-small){
        font-size: 55px;
      }
    }
    .bg{
      width: 100%;
      position: absolute;
      left:0;
      top: 0;
      background: $light-blue;
      height: 100%;

    }
  }


  .svg-hero-inside{


  }



  .mask__shape {
    fill: white;
  }
  .shape--fill {
    fill: $shape-color;
  }
  .text--transparent {
    fill: transparent;
  }
  .box-with-text {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    padding-top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .text-fill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
  }
  .video {
    position: absolute;
    top:0;
    width: 100%;
  }

  .seperator{
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -11px;
    transform: translate(0, -50%);

    @include media(x-small){
      margin-top: -17px;
    }

    @include media(small){
      top: 240px;
      width: 784px;
      left: 50%;
      transform: translate(-50%, 0);
      margin-top: 2px;
    }

    @include media(medium){
      margin-top: 0;
    }

    .no-js &{
      display: none;
    }

    .line{
      width: 50%;
      top: 50%;
      height: 1px;
      background: $dark-blue2;
      display: block;
      position: absolute;
      z-index: 2;
      &._line-1{
        left: 0;
      }
      &._line-2{
        right: 0;
      }
    }
    .text{
      color: $dark-blue2;
      font-family: $DINCBold;
      font-size: 22px;
      background: $light;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0 20px;
      z-index: 5;
      line-height: 22px;
      transform: translate(-50%,-50%);
        
        svg {
            @include media(max-small) {
                width: 24px;
                height: auto;
            }
            @include media(x-small) {
                width: 50px;
            }
            @include media(small) {
                width: initial;
            }
        }    
        
      @include media(x-small){
        font-size: 40px;
        padding: 0 40px;
        line-height: 40px;
      }
      @include media(small){
        font-size: 50px;
        line-height: 50px;
      }
    }
  }

  /* Other stuff */

  .svg-defs {
    width: 624px;
    height: 440px;
  }
  .svg-inverted-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .svg-hero-inside {
    width: 349px;
    height: 200px;
    position: relative;
    font-family: $DINCBold;
    font-size: 200px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: left;
    overflow: hidden;
    padding: 0px 0;

    @include media(x-small){
      width: 690px;
      height: 421px;
    }

    @include media(small){
        width: $hero-width;
        height: 520px;
    }



    .__text-2{
      font-size: 250px;
    }

    img{
      max-width: 100%;
    }
    video{


      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;


      @include media(small){

        left: 0;
        transform: translateY(0);
        top: -2px;
        height: auto;
      }

      .no-js &{
        display: none;
      }
    }
  }

}


//inner pages hero
$page-hero-height:290px;

.page-hero{
  height: 200px;
  position: relative;
  overflow: hidden;
  color: #fff;

  @include media(x-small){
    height: $page-hero-height;
  }

  .bg{
    height: 100%;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    z-index: -1;

    @include media(x-small){
      background-position: left top;
    }


    div{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .container{
    position: relative;
    z-index: 2;
    height: 100%;
  }
  .hero-label{
    position: absolute;
    right: 20px;
    font-size: 10px;
    bottom: 30px;
    text-align: right;

    @include media(x-small){
      font-size: 12px;
    }
  }
  .hero-title{
    position: absolute;
    top: 0;
    max-width: 65%;

    @include media(x-small){
      bottom: 0;
      top: auto;
    }

    @include media(medium){
      max-width: 820px;

    }

    h1{
      line-height: 38px;
      font-size: 38px;
      font-family: $DINCBold;

      @include media(x-small){
        line-height: 60px;
        font-size: 60px;
      }

      @include media(small){
        line-height: 70px;
        font-size: 70px;
      }

    }
  }

}
