.dropdown-backdrop{
  display: none;
}
#header{
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 0 25px 30px 0 rgba(36,50,66,0);
  @include transition(all 0.3s);

  .scroll &{
    position: fixed;
    top: 0;
    background: $light;
    z-index: 50;
    border-color: #f5f5f5;
    box-shadow: 0 25px 30px 0 rgba(36,50,66,0.08);
  }

  .header-inside{
    padding: 12px 0;
    @include transition(all 0.3s);

    @include media(x-small){
        padding: 25px 0;
    }

    .scroll &{
      padding: 15px 0;
    }

  }
}

.brand{
  width: 267px;

  @include media(x-small){
    width: 350px;
  }

  svg{


    @include media(x-small){
      height: 63px;
    }
  }
}

#primary-menu{
  background: $dark-blue2;
  position: fixed;
  height: 100% !important;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 50;
  visibility: hidden;
  opacity: 0;
  @include transition(all .3s);
  color: $light;
  padding: 90px 0 40px;
  font-family: $DINCBold;
  text-transform: uppercase;

  @include media(x-small){
    padding: 150px 0 0;
  }

  &.in{
    visibility: visible;
    opacity: 1;
  }

  a{
    color: $light;
    position: relative;
    @include transition(all .3s);

    &:hover{
      color: #559ebe;
    }

    &.dropdown-toggle{

      &::after{
        content: "";
        background: url(../images/link-arrow.svg) no-repeat 0 0;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        right: -22px;
        top: 0;
        transform: rotate(0);
        @include transition(all 0.3s);

        @include media(x-small){
            top: 3px;
        }
      }

      &:hover::after{
        background: url(../images/link-arrow-blue.svg) no-repeat 0 0;
      }





    }

  }


  li.open{

    >a{
      background: 0;
      color: #559ebe;


      &.dropdown-toggle{

        &::after{
          background: url(../images/link-arrow-blue.svg) no-repeat 0 0;
          transform: rotate(90deg);

          @include media(x-small){
            transform: translateX(10px) rotate(0);
          }
        }


      }


    }


    .dropdown-menu{

      a{
        &::after{
          display: none !important;
        }
      }
    }

  }

  li.active{

    >a{
      background: 0;
    }
  }



  .menu-inside{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    >.container{
      height: 100%;
    }
  }
}

.menu-items{


  height: 100%;
  padding: 0px 0  0;
  margin: 0;
  background: #123150;
  list-style: none;
  font-size: 20px;
  max-width: 90%;




    @include media(x-small){
      font-size: 22px;
      width: 30%;
      float: left;
      max-width: none;
    }


    @include media(small){
      width: 30%;
    }

    @include media(medium){
        padding: 30px 0 0 0;
    }



    >li{
      padding: 10px 0;

      opacity: 0;
      visibility: hidden;
      transform: translateX(-50px);
      @include transition(all 0.3s);

      @include media(x-small){
        padding: 10px  0;
        max-width: 165px;
      }


      @include media(small){

        max-width: 226px;
      }


      &:nth-child(1){ transition-delay: 0.1s}
      &:nth-child(2){ transition-delay: 0.2s}
      &:nth-child(3){ transition-delay: 0.3s}
      &:nth-child(4){ transition-delay: 0.4s}
      &:nth-child(5){ transition-delay: 0.5s}
      &:nth-child(6){ transition-delay: 0.6s}
      &:nth-child(7){ transition-delay: 0.7s}
      &:nth-child(8){ transition-delay: 0.8s}
      &:nth-child(9){ transition-delay: 0.9s}
      &:nth-child(10){ transition-delay:1s}
      &:nth-child(11){ transition-delay:1.1s}

      .in &{

        visibility: visible;
        opacity: 1;
        transform: translateX(0);

      }

      a{
        letter-spacing: 2px;
        text-decoration: none;
        display: block;
        line-height: 1.1;

        &:hover{
          background: transparent;

        }
      }

      li{
        padding: 3px 0;

        @include  media(x-small){
          padding: 10px 0;
        }

        a{
          font-size: 16px;
          @include  media(x-small){
            font-size: 25px;
          }
        }
      }
    }



    li.open{

      >.dropdown-menu{
        background: 0;
        box-shadow: none;
        border:0;
        margin-top: 10px;


        @include media(x-small){

          visibility: visible;
          opacity: 1;
          transform: translateX(0);
          left: 283px;
        }


        .dropdown-menu{
          display: block;
          padding-left: 20px;
          text-transform: none;
        }
      }



    }




    //hide dropdown for custom implementation
    ul{


      @include media(x-small){
        display: none !important;
      }
    }



}

.dropdown-menu{
  position: static;
  background: 0;
  border: 0;
  box-shadow: none;

  @include media(x-small){
    position: absolute;
  }
}
.sub-menus{
  float: left;
  width: 50%;
  height: 100%;
  display: none;
  overflow: hidden;

  @include media(x-small){
    display: block;
    width: 70%;
  }

  @include media(small){
    width: 70%;
  }

  .menu{
    height: 100%;
    float: left;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%);
    @include transition(all .4s);
    position: relative;

    &.menu-a{
      background: #27566e;
      width: 100%;
      z-index: 10;

      @include media(x-small){
        width: 50%;
      }
      @include media(small){
        width: 50%;
      }
    }
    &.menu-b{
      background: #2d5c74;
      width: 50%;
      z-index: 5;
    }

    &.active{
      visibility: visible;
      opacity: 1;
      transform: translateX(0);


      li{
        opacity: 1;
        transform: translateY(0%);


      }
    }


    ul{
      position: static;
      display: block;
      background: 0;
      box-shadow: none;
      border: 0;
      float: none;
      font-size: 20px;
      padding: 100px 20px;


      @include media(x-small){
        font-size: 20px;
      }

      ul{
        display: none;
      }

      li{
        padding: 7px 0;


        a{
          line-height: .9;
          white-space: normal;

          &:hover, &:focus, &:active{
            background: 0;
          }

          &.dropdown-toggle{

            &::after{
              right: 0 !important;

            }
          }


        }
      }
    }


  }
  &::after{
    @extend %clear-fix;
  }
}



.navbar-toggle{
    margin: 0;
    padding: 0;
    width: 44px;
    height: 44px;
    background: transparent;
		border:0;
		border-radius: 0;
		z-index: 90;
    right: 0;
    top: 0;


    @include media(x-small){
      display: block;
      top: 10px;
    }

		>span{
			display: block;
		}


    &.collapsed .icon-bar{
      background-color: #53524C;
    }
		.icon-bar{
		  background-color: #fff;
		  position: relative;
		  margin: 0 auto;
		  width: 100%;
		  height: 4px;
		  transform: none;
      @include transition(all 0.3s ease-in-out);
			float: right;
			clear: both;



      &+.icon-bar{
          margin-top: 8px;
      }
		}

		&:hover >.icon-bar:nth-child(1){
			// width: 15px;
		}

		&:hover >.icon-bar:nth-child(3){
			// width: 18px;
		}

		&.collapsed .icon-bar:nth-of-type(1) {
		  top: 1px;
		  transform: none
		}
		&.collapsed .icon-bar:nth-of-type(2) {
		  background-color: #53524C;
		}
		&.collapsed .icon-bar:nth-of-type(3) {
		  top: -1px;
		  transform: none
		}

		.icon-bar:nth-of-type(1) {
		  top: 14px;
		  transform: rotate(45deg)
		}
	 	.icon-bar:nth-of-type(2) {
		  background-color: transparent;
		}
		.icon-bar:nth-of-type(3) {
		  top: -10px;
		  transform: rotate(-45deg)
		}
}
