@font-face {
    font-family: 'DINPro';
    src: url('../fonts/DINPro-Black.eot');
    src: url('../fonts/DINPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Black.woff2') format('woff2'),
        url('../fonts/DINPro-Black.woff') format('woff'),
        url('../fonts/DINPro-Black.svg#DINPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DINPro';
    src: url('../fonts/DINPro-Medium.eot');
    src: url('../fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Medium.woff2') format('woff2'),
        url('../fonts/DINPro-Medium.woff') format('woff'),
        url('../fonts/DINPro-Medium.svg#DINPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DINPro';
    src: url('../fonts/DINPro-Bold.eot');
    src: url('../fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Bold.woff2') format('woff2'),
        url('../fonts/DINPro-Bold.woff') format('woff'),
        url('../fonts/DINPro-Bold.svg#DINPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DINPro';
    src: url('../fonts/DINPro-Light.eot');
    src: url('../fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Light.woff2') format('woff2'),
        url('../fonts/DINPro-Light.woff') format('woff'),
        url('../fonts/DINPro-Light.svg#DINPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}



@font-face {
  font-family: 'DINCondensed-Bold';
  src: url('../fonts/DINCondensed-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINCondensed-Bold.woff') format('woff'),
  url('../fonts/DINCondensed-Bold.ttf')  format('truetype'),
  url('../fonts/DINCondensed-Bold.svg#DINCondensed-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
    font-family: 'din_condensedbold';
    src: url('../fonts/din_condensed_bold-webfont.eot');
    src: url('../fonts/din_condensed_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/din_condensed_bold-webfont.woff2') format('woff2'),
         url('../fonts/din_condensed_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
