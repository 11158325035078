body{
  font-family: $DINPro;
  font-size: 17px;
  font-weight: 500;
  color: $dark-blue;
}


.desktop-left{


  @include media(x-small){
    float: left !important;
  }
}
.desktop-right{
  @include media(x-small){
    float: right !important;
  }
}

%clear-fix{
  clear: both;
  content: "";
  display: block;
}

p{
  margin-bottom: 30px;

  &.intro{
    font-family: $DINCBold;
    letter-spacing: 2px;
    font-size: 22px;
  }
}


.p-md{
  font-size: 30px;
  font-family: $DINCBold;
  color: $dark-blue2;
}
.light-blue{
  color: $light-blue;
}
.dark-blue{
  color: $dark-blue;
}
.dark-blue2{
  color: $dark-blue2;
}
.no-margin-bottom{
  margin-bottom: 0 !important;
}
body{

  &.bs-collapse{
    overflow: hidden;
  }
}
.container{

  @include media(medium-large){
      width: 1200px;
  }

  @include media(large){
      width: 1440px;
  }


}
