.btn{
  font-size: 16px;
  font-family: $DINCBold;
  text-transform: uppercase;
  background: $dark-blue;
  border-radius: 0;
  color: $light;
  padding: 7px 20px 7px;
  min-width: 102px;
  letter-spacing: 2px;
  @include transition(all 0.3s);

  &:hover, &:active, &:focus{
    color: $light;
  }

  &:hover{
    background: $light-blue;
  }


}


.mod-awesome-img{
   width: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   margin-bottom: 30px;


   @include media(x-small){

   }

   .mod-header{
     font-size: 18px;
     font-weight: 500;
     color: $light;
     text-align: center;
     font-family: $DINCBold;
     letter-spacing: 1px;
     margin: 0 auto;
     padding: 10px 0;
     background: #053351;
     line-height: 25px;

     @include media(x-small){
       font-size: 25px;
     }

     &::after{
       @extend %clear-fix;
     }

     .mh{

       &.__left{
         float: left;
       }
       &.__right{
         float: right;
       }

     }
   }
   .mod-body{
     background: rgba(255, 255, 255, 0.9);
     width: 90%;
     position: absolute;
     bottom: 25px;
     top: 25px;
     left: 50%;
     transform: translateX(-50%);

     @include media(x-small){
       bottom: 60px;
       width: 80%;
       top: 60px;
     }


     .mod-content{
       position: absolute;
       left: 20px;
       top: 20px;
       right: 20px;
       bottom: 20px;


       @include media(x-small){
         left: 40px;
         top: 40px;
         right: 40px;
         bottom: 40px;
       }
     }

     .mod-body-inner{
       position: absolute;
       left: 0;
       width: 100%;
       top: 44px;
       bottom: 38px;


       @include media(x-small){

       }

     }

     .seperator{
       position: absolute;
       top: 50%;
       width: 100%;
       left: 50%;
       transform: translate(-50%,-50%);

       .line{
        width: 32%;
         top: 50%;
         height: 1px;
         background: #053351;
         display: block;
         position: absolute;
         z-index: 2;


         @include media(x-small){
           width: 42%;
         }

         &._line-1{
           left: 0;
         }
         &._line-2{
           right: 0;
         }
       }
       .text{
         color: #053351;
         font-family: $DINCBold;
         font-size: 21px;
         text-transform: uppercase;
         position: absolute;
         top: 50%;
         left: 50%;
         padding: 0 40px;
         z-index: 5;
         transform: translate(-50%,-50%);
       }
     }

     .text{
       color: #053351;
       font-family: $DINCBold;
       font-size: 57px;
       line-height: 90px;
       text-align: center;
       letter-spacing: 5px;
       position: absolute;
       left: 50%;
       transform: translateX(-50%);

       @include media(small){
         font-size: 97px;
       }


       &.text-top{
         top: 15px;
       }

       &.text-bottom{
         font-size: 73px;
         bottom: 3px;

         @include media(small){
           font-size: 122px;
         }

       }

     }
   }

   .mod-footer{
     font-size: 9px;
     font-family: $DINPro;
     text-align: center;
     color: #053351;
     position: absolute;
     bottom: 0;
     width: 100%;
     left: 50%;
     font-weight: 300;
     letter-spacing: 2px;

     transform: translateX(-50%);

     @include media(small){
       font-size: 10px;
     }

   }
}

.mod-btn-group{
  text-transform: uppercase;
  font-family: $DINCBold;
  font-size: 0;
  width: 100%;
  display: block;
   margin-bottom: 30px;

  a{
    letter-spacing: 2px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    background: #27566E;
    color: $light;
    font-size: 13px;
    text-decoration: none;
    padding:8px 10px;
    width: 100%;

    @include media(x-small){
      width: 50%;
    }

    @include media(medium){
      font-size: 18px;
    }
    &:first-child{
      background: $dark-blue2;
    }
  }
}

.mod-leaders{


  .leader{
    background: rgba(39, 86, 110,.8);
    padding: 38px 18px;
    margin-bottom: 30px;

    &::after{
      @extend %clear-fix;
    }
    .col-left{
      width: 100%;

      @include media(x-small){
        float: left;
        width: 22%;
      }

      @include media(medium){
          width: 27%;
      }
    }
    .col-right{
      width: 100%;


      @include media(x-small){
        float: right;
        width: 78%;
          padding-left: 20px;
      }
      @include media(medium){
        width: 73%;
      }
    }
    .name{
      color: $light;
      font-family: $DINCBold;
      font-size: 30px;
      margin-bottom: 17px;
      line-height: 30px;
    }
    .position{
      font-size: 18px;
      font-family: $DINCBold;
      margin-bottom: 15px;
      color: #8CC4DE;
      line-height: 18px;
    }
    .photo{
      img{

        max-width: 100%;
      }
      margin-bottom: 20px;
    }

    .bio{
      color: $light;
      font-size: 17px;
      font-weight: 500;
      letter-spacing: 1px;
    }

  }
}

$grid-gap: 9px;
.mod-grid {
  margin: 0 -$grid-gap;


  @include media(medium){
    margin-bottom: 200px;
  }

  .grid-sizer,
  .grid-item {
    width: 100%;

    @include media(x-small){
      width: 50%;
    }
    @include media(medium){
      width: calc(100% / 3);
    }
  }

  .grid-item {
    height: 297px;
    color: $light;


    &.empty{
      opacity: 0;
      display: none;

      @include media(medium){
        display: block;
      }

    }

    .grid-item-content{
      padding: $grid-gap;
      height: 100%;
    }

    .grid-body{
      position: absolute;
      left: $grid-gap;
      top: $grid-gap;
      right: $grid-gap;
      bottom: $grid-gap;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      @include transition(all 0.5s);


      .img{
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 5;
        transform: translate(-50%,-50%) scale(1.2);
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;

        background-position: center;
        @include transition(all 1.5s);

        >div{
          position: absolute;
          top: 15px;
          bottom: 15px;
          left: 15px;
          right: 15px;
          background-size: cover;
        }

      }

    }
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 10;
      padding: 27px 33px;
      font-size: 22px;
      font-family: $DINCBold;
      line-height: 22px;

      li{
        padding: 3px 0;

        a{
          color: $light;
          text-decoration: none;
          padding-left: 20px;
          position: relative;
          display: inline-block;
          letter-spacing: 1px;
          opacity: 0;
          visibility: hidden;
          transform: translateX(-20px);
          @include transition(all 0.3s .2s);

          &:hover{
            @include transition(all 0.3s 0.1s !important);
            color: #8cc4de;
          }

          span{
            background: url(../images/arrowhead2.svg) no-repeat;
            width: 10px;
            height: 10px;
            display: block;
            position: absolute;
            top: 6px;
            left: 0;
            @include transition(all 0.3s);

          }
        }
      }
    }
    .grid-cover{
      position: absolute;
      left: $grid-gap;
      top: $grid-gap;
      bottom: $grid-gap;
      right: $grid-gap;
      font-size: 40px;
      line-height: 40px;
      font-family: $DINCBold;
      padding: 20px;
      line-height: 52px;
      visibility: visible;
      opacity: 1;
      @include transition(all 0.5s);

      @include media(small){
        padding: 45px;
        line-height: 52px;
        font-size: 50px;
      }

      span{
        display: block;
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
        @include transition(all 0.3s);

        &:nth-child(1){ transition-delay: 0.1s}
        &:nth-child(2){ transition-delay: 0.2s}
        &:nth-child(3){ transition-delay: 0.3s}
        &:nth-child(4){ transition-delay: 0.4s}
        &:nth-child(5){ transition-delay: 0.5s}

      }

    }


    &:hover, &.hovered{

      .grid-body{
        visibility: visible;
        opacity: 1;
      }
      .img{
          transform: translate(-50%,-50%) scale(1);
      }
      .grid-cover{
        visibility: hidden;
        opacity: 0;

        span{
          visibility: hidden;
          opacity: 0;
          transform: translateY(60px);
        }
      }

      ul{

        li{

          a{
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
          }

          &:nth-child(1) a{ transition-delay: 0.1s}
          &:nth-child(2) a{ transition-delay: 0.2s}
          &:nth-child(3) a{ transition-delay: 0.3s}
          &:nth-child(4) a{ transition-delay: 0.4s}
          &:nth-child(5) a{ transition-delay: 0.5s}
          &:nth-child(6) a{ transition-delay: 0.6s}
          &:nth-child(7) a{ transition-delay: 0.7s}
          &:nth-child(8) a{ transition-delay: 0.8s}
          &:nth-child(9) a{ transition-delay: 0.9s}
          &:nth-child(10) a{ transition-delay:1s}
          &:nth-child(11) a{ transition-delay:1.1s}
        }
      }

    }

  }


  .mod-grid-gfx{
    display: none;
    position: absolute;

    @include media(medium){
      display: block;
    }

    &.__gfx1{
      left: 50%;
      width: 688px;
      height: 417px;
      margin-left: 200px;
      margin-top: -70px;

      img{
        max-width: 100%;
        @include media(x-large){
          max-width: none;
        }
      }
    }
    &.__gfx2{
      right: 50%;
      width: 487px;
      height: 487px;
      bottom: -201px;
      margin-right: 199px;
    }
  }


}

#globe-svg{
  width: 487px;
  height: 487px;
}

.mod-widget{
  font-size: 28px;
  font-family:$DINCBold;

  .widget-title{
    font-size: 40px;
    color: $dark-blue2;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 30px;
    text-transform: uppercase;

    @include media(x-small){
      font-size: 50px;
    }

    &::after{
      content: "";
      display: block;
      height: 2px;
      background: $dark-blue2;
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }

  ul:not(.mod-social-media){
    list-style: none;
    padding: 0;
    margin: 0;

    li{

      a{
        text-decoration: none;
        color: $dark-blue2;
        position: relative;
        display: inline-block;
        @include transition(all 0.3s);
        padding: 5px 0;
        letter-spacing: 1px;

        &:hover{
          padding: 5px 50px 5px 50px;

          &::before{
            transform: translate(16px,-50%);
            opacity: 1;
            visibility: visible;
          }
        }

        &::before{
          content: "";
          background:url(../images/link-arrow.svg) no-repeat 0 0;
          width: 11px;
          height: 11px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          opacity: 0;
          visibility: hidden;
          transform: translate(0,-50%);
          @include transition(all 0.3s);

        }

        &:hover{
          background-color: $light-blue;
          color: $light;
        }

      }
    }
  }

}
#sidebar .mod-widget ul:not(.mod-social-media) li a{
  padding: 5px 0;
  background: 0;
  &::before{
    display: none;
  }

  &:hover{
    color: $light-blue;
  }
}
.mod-social-media{
  list-style: none;
  padding: 15px 0 0 0;
  margin: 0;
  font-size: 0;
  display: block;
  li{
    display: inline-block;
    padding: 0 8px;


    &:hover{
      a{
        background: $dark-blue2;
      }
    }

    &.linkedin:hover{a{background: #0173b3;}}
    &.twitter:hover{a{background:#1ea1f2;}}
    &.instagram:hover{a{background: #D60E44;}}
    &.youtube:hover{a{background:#E90B1C;}}

    a{
      text-align: center;
      width: 37px;
      height: 37px;
      background: #8CC4DE;
      display: inline-block;
      position: relative;
      border-radius: 5px;
      @include transition(all 0.3s);



      i{
        font-size: 25px;
        color: $light;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}

$tbc-height: 820px;
.mod-tabs{


  .nav-tabs{
    border: 0;
    float: right;
    font-family: $DINCBold;
    overflow: hidden;


    li{

      a{
        letter-spacing: 1px;
        padding: 12px 20px 10px;
        color: #53524C;
        background: #EDEDED;
        border-radius: 0;
        font-size: 18px;
        line-height: 18px;
        border: 0 !important;
        margin: 0;
        margin-right: 8px;
        min-height: 58px;
        text-align: center;
        display: table;

        span{
          display: table-cell;
          vertical-align: middle;
          height: 100%;
        }

        @include media(x-small){
          font-size: 16px;
          padding: 12px 18px 10px;
        }

        @include media(medium){
          font-size: 18px;
          padding: 12px 40px 10px;
          margin-right: 15px;
        }
      }

      &.active a{
        color: #fff;
        background: #27566E;
      }



      &:nth-child(1) a{ color: #6DBA5C}
      &:nth-child(2) a{ color: #569EBE}
      &:nth-child(3) a{ color: #2A8895}
      &:nth-child(4) a{ color: #27566E}
      &:nth-child(5) a{ color: #5486B7}

      &.active:nth-child(1) a{ background: #6DBA5C;color: #fff;}
      &.active:nth-child(2) a{ background: #569EBE;color: #fff;}
      &.active:nth-child(3) a{ background: #2A8895;color: #fff;}
      &.active:nth-child(4) a{ background: #27566E;color: #fff;}
      &.active:nth-child(5) a{ background: #5486B7;color: #fff;}

      &:last-child a{
        margin-right: 0;
      }

    }



  }


  .tab-content{
    display: none;

    @include media(x-small){
      display: block;
    }

    .tab-pane{
      height: 100%;
      overflow: hidden;




      img{
        max-width: 100%;
      }
    }




    #tab-4{
        .section-title {
            color: #27566E;
        }
        .section-sub-title {
            color: #27566E;
        }
        p {
            color: #123150;
        }
      .btn{

        background: $dark-blue2;
        color: #fff;
      }
    }

    #tab-3{

      .section-title {
        color: #2A8895;
      }
      p {
        color: #123150;
      }
      .btn{
        background: $dark-blue2;
        color: #fff !important;

        &:hover{
          background: $light-blue;
            color: #fff !important;
        }
      }

    }

    #tab-2{

        .__col-2{

          .inner{
              padding-right: 0;
          }
        }
        .inner{
              padding-right: 0;
              text-align: center;

            .section-title {
                color: #569EBE;
            }

            p {
                color: #123150;
            }

            #imgblueprint {
                width: 80%;
                margin-bottom: 20px;
            }

          }

    }

    #tab-5{

        .__col-2{

          .inner{
              padding-right: 0;
          }
        }
        .inner{
              padding-right: 0;

            .section-title {
                color: #5486B7;
            }

        }

        .btn {

        }

    }

    #tab-4{

      video{

        width: 100%;
        height: auto;
      }
    }

    .tbc{
      height: 100%;
      position: relative;
      padding-bottom: 30px;


      &::after{
        @extend %clear-fix;
      }


      .bg-group ~ .container *,
      .bg ~ .inner *,
      .bg ~ .container *,
      .bg ~ .col *{
        color: $light;
      }

      .section-sub-title{
        font-size: 45px;
        margin-bottom: 10px;
        line-height: 50px;
        padding-top: 5px;

      }

      &.__2-cols{


      }

      .bg-group{
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        .bg{
          right: 0;
          width: 100%;

          &::after{
            position: absolute;
            left: 0;
            top:0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            content: "";
            display: block;
          }

          &.__video{
            width: 100%;
            overflow: hidden;
          }
          &.__image{
            right: 0;
            left: auto;
            width: 48%
          }
        }
      }

      .col{
        height: 100%;
        float: left;
        width: 50%;
        position: relative;

        &.__col-1{
            width: 54%;

        }
        &.__col-2{
          width: 46%;
        }


      }




      .bg{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;



        video{
            height: 100%;
            width: auto;

          @include media(small){


          }

          @include media(medium){
            height: auto;
            width: 100%;
          }
        }


        div{
          position: absolute;
          z-index: 5;
          height: 100%;
          left: 0;
          width: 100%;
          top: 0;
        }





      }

      .inner{
        padding: 40px 10px;

        @include  media(small){
          padding: 40px;
        }

        @include  media(large){
          padding: 80px;
        }



        &.__v2{
          padding: 80px 0 0 0;
        }

        &.__v3{
          padding: 80px 0 0 0;
        }

      }
    }



  }



}
.mod-styled-list{
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
  li{

    margin-bottom: 10px;
    a{
      background:rgba(109, 186, 92, 0.65);
      text-decoration: none;
      padding: 40px 40px;
      display: block;
      position: relative;
      @include transition(all .3s);


      &::before{
        content: "";
        background:url(../images/link-arrow.svg) no-repeat 0 0;
        width: 11px;
        height: 11px;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        opacity: 0;
        visibility: hidden;
        transform: translate(0,-50%);
        @include transition(all 0.3s);

      }

      &:hover{
        background:rgba(109, 186, 92, 1);

        &::before{
          transform: translate(15px,-50%);
          visibility: visible;
          opacity: 1;

        }
      }
    }
  }
}

.mod-style-2 {
    li {
        a {
            background: rgba(39, 86, 110, 0.65);

            &:hover {
                background: rgba(39, 86, 110, 1);
            }
        }
    }
  .meta{
    display: block;
    font-family: $DINCBold;
    color: #8CC4DE !important;
  }
  .summary{
    display: block;
  }
}

.mod-styled-2-list{
  list-style: none;
  padding: 0;
  margin: 0;


  @include media(medium){
    margin-left: -30px;
  }

  li{
    padding: 20px 0px;
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 20px;


    a{
      text-decoration: none;
      position: relative;
      display: block;


      @include media(medium){
        padding-left: 30px;
      }

      &::before{
        content: "";
        background:url(../images/link-arrow.svg) no-repeat 0 0;
        width: 11px;
        height: 11px;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        opacity: 0;
        visibility: hidden;
        transform: translate(-30px,-50%);
        @include transition(all 0.3s);

      }

      &:hover{

        &::before{


          @include media(medium){
            transform: translate(0,-50%);
            visibility: visible;
            opacity: 1;
          }

        }
      }
    }
  }

  .meta{
    display: block;
    font-family: $DINCBold;
    color: $light-blue !important;
  }
  .summary{
    display: block;
  }
}

.mod-entries{

  a{
    display: block;
    text-decoration: none;
  }

  .entry-wrap {
      background-color: #5486B7;
      min-height: 340px;
      padding: 10px 20px;
      margin-bottom: 60px;
      display: block;
      @include transition(all 0.3s);

      @include media(medium){
        padding: 10px 30px;
      }

      &:hover{
        background: #416C96;
      }

  }
  .entry-title{
    border-top: 1px solid #8CC4DE;
    border-bottom: 1px solid #8CC4DE;
    font-family: $DINCBold;
    font-size: 17px;
    padding: 15px 0;
    display: block;
    margin-bottom: 30px;
    letter-spacing: 1px;
    line-height: 18px;


    @include media(small){
      text-align: center;
      font-size: 18px;
      line-height: 18px;
    }

    @include media(medium){
      font-size: 21px;
      line-height: 23px;
    }
  }
  .entry-link{
    height: 100%;
    display: block;
  }
  .entry-img{
    margin-bottom: 30px;
    display: block;
    img{
      width: 100%;
    }
  }
  .entry-summary{
    display: block;
    height: 100%;
    font-family: $DINCBold;
    letter-spacing: 1px;

    p:first-child{
      font-family: $DINPro;

      span{
        color: #1a85b5 !important;

        @include media(x-small){
          color: #B5DFF2 !important;
        }
      }
    }


    span{
      display: block;
    }
  }
  .entry-meta{
    display: block;
    opacity: .5;
    margin-bottom: 10px;
  }
}


#mod-cld-svg{
  width: 100%;

  .grp{

    >path{
        fill: #2A8895;
        stroke: transparent;
      @include transition(all 0.3s)
    }

    >text{

    }

    >g{
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.3s)
    }


    &:hover{
      cursor: pointer;
      >path{
        fill:#52C2D1;

      }

      >g{
        visibility: visible;
        opacity: 1;
      }

    }

  }






}



.panel-group{


  .panel{
    border-radius: 0;


    &:nth-child(1) .panel-heading  a{ background: #27566E; }
    &:nth-child(1) .panel-heading  a.collapsed{ color: #27566E;}


    &:nth-child(2) .panel-heading  a{ background: #457C95; }
    &:nth-child(2) .panel-heading  a.collapsed{ color: #457C95;}


    &:nth-child(3) .panel-heading  a{ background:#279D79 ; }
    &:nth-child(3) .panel-heading  a.collapsed{ color:#279D79 ;}

    &:nth-child(4) .panel-heading  a{ background:#123150 ; }
    &:nth-child(4) .panel-heading  a.collapsed{ color:#123150 ;}



    &:nth-child(5) .panel-heading  a{ background:#5486B7 ; }
    &:nth-child(5) .panel-heading  a.collapsed{ color:#5486B7 ;}

    .panel-heading{
      background: #EAEAEA;
      font-family: $DINCBold;
      padding: 0;



      a{
        text-decoration: none;
        padding: 10px 15px;
        display: block;
        color: #fff;
        @include transition(all 0.3s);



        &.collapsed{
          background: #EAEAEA;
          color: #333;
          cursor: pointer;
        }
      }





    }

    img{
      max-width: 100%;
    }

    #mod-cld-svg{

      height: auto;
      margin-bottom: 50px;
      text{
        fill:$light;
      }
      path{
        stroke:#2A8895;
      }
      .grp>g{
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .panel-body{
    padding-left: 0;
    padding-right: 0;


    .tbc{

      .bg,
      .bg-group{
        display: none;
      }


    }
  }

  .mod-styled-2-list{
    margin-left: 0;

    a{
      padding-left: 0;
    }
  }

  .entry-wrap{
    background: transparent;
    padding: 0;
    min-height: 0;
    margin: 0;
  }

  .mod-styled-list{


      li{


          border-bottom: 1px solid #EAEAEA;


        a{
          background: 0;
          padding: 20px 0;

          &::before{
            display: none;
          }
        }
      }
  }
  .mod-entries{

    >div[class*=col]{
      /*border-bottom: 1px solid #EAEAEA;
      padding-bottom: 20px;
      margin-bottom: 20px;*/
    }

    .entry-title{
      margin-top: 0;
    }
  }

  .section-sub-title,
   .section-title{
     padding-bottom: 0;
    margin-bottom: 20px;
  }


}

.mod-lightbox{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: red;
  color: $light;
  opacity: 0;
  visibility: hidden;
  background: #52C2D1;
  @include transition(all 0.3s)
  z-index: 50;


  @include media(x-small){
    position: absolute;
  }



  &.active{
    visibility: visible;
    opacity: 1;
  }

  >.container{
    position: relative;
    display: table;
    height: 100%;
  }

  .mod-lb-details{
    padding: 40px 0 40px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;


    @include media(medium){
        padding: 100px 0 40px;
    }
  }

  .mod-lb-title{
    font-size: 33px;
    font-family: $DINCBold;
    margin-bottom: 50px;
    line-height: 33px;


    @include media(x-small){
      font-size: 53px;
      line-height: 53px;
    }
    @include media(medium){
      font-size: 73px;
      line-height: 73px;
    }
  }

  .mod-lb-summary{
    font-size: 17px;
    font-family: $DINPro;
      p {
          color: #fff !important;
      }

    @include media(medium){
      font-size: 22px;
    }
  }

  .x{
    position: absolute;
    right: 20px;
    top: 40px;
    font-size: 25px;
    opacity: 0.5;
    font-family: $DINCBold;
    @include transition(all 0.3s);
    color: $light;

    &:hover{
      opacity: 1;
    }

  }


}


.main-content .chart-name{
  color: #063252;
    line-height: 1;
  span{
    color: #6a737e;
      font-weight: 300;
      font-family: 'DINPro';
      font-size: 17px;
      letter-spacing: 1px;

  }
}
.chart{
  margin-bottom: 30px;


}


.legend-wrap{
  padding: 20px 0;

  .legend{
    display: inline-block;
    vertical-align: middle;
    padding-right: 40px;

    .icon{
      width: 15px;
      display: inline-block;
      vertical-align: middle;
      height: 15px;
    }
  }
}



.legend .icon.icon-line{
  position: relative;
}


.legend .icon.icon-line span{
  position: absolute;
}

.legend .icon.icon-line .circle{
  width: 10px;
  height: 10px;
  left: 50%;
  top: 50%;
  margin-top: -5px;
  margin-top: -4px\9;
  margin-left: -5px;
  z-index: 10;
  display: block;
  background-repeat: no-repeat;
  background-position: 0 0;
  border-radius: 50%;
}

.legend .icon.icon-line .line{
  width: 100%;
  height: 2px;
  top:50%;
  transform: translateY(-50%);
  z-index: 5;
}



.legend .icon span{
  display: block;
  width: 100%;
  float: left;
  height: 15px;
  width: 15px;
}
.legend.fixed-width .icon{
  max-width: 30px;
}
.legend.fixed-width .title{

}
.legend .icon::after{
  content: "";
  display: block;
  clear: both;
}



  .legend .title{
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  line-height: 1.2;
  color: #000;
  padding-left: 10px;
}



.table-legend{
  font-size: 16px;
}
.table-legend ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

.table-legend ul li{
  list-style: none;
  font-size: 13px;
  line-height: 2;
}

.tbl-heading{
  text-align: right;
  padding-bottom: 10px;
}
.tbl-body{
  font-size: 0;

}
.tbl-body li{
  display: inline-block;
  font-size: 12px;
}
.tbl-body li{
  width: 100%;
}

.table-legend ul li .icon{
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -4px;
  margin-bottom: 0;
  float: none !important;
}
.table-legend ul li .tbl-label{
  float: right;
}
.table-legend ul li::after{
  content: "";
  display: block;
  clear: both;
}

@media( min-width: 768px ){
  .no-left-gutter{
    padding-left: 0;
  }


}

@media( max-width: 767px){
  .legend .title{
    font-size: 13px;
  }
  .legend-wrap .row div[class*=col]{
    padding-left: 0;
    padding-right: 0;
  }
  .legend-wrap .row div[class*=col]:first-child{
    padding-left: 15px;
  }


}
