#footer{
  background: $dark-blue2;
  text-align: center;
  padding: 25px 0;


  .copyrights{
    margin: 0;
    padding: 0;
    color: $light;
    font-size: 12px;
    @include media(x-small){
      font-size: 17px;
    }

  }
}
