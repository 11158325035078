.section{
  padding-bottom: 50px;
  position: relative;

  @include media(x-small){
    padding-bottom: 100px;
  }

  .section-title{
    font-size: 35px;
    font-family: $DINCBold;
    color: $light-blue;
    margin:0 0 40px 0;
    letter-spacing: 2px;


    @include media(x-small){
      font-size: 50px;
    }
    @include media(medium){
      font-size: 70px;
    }

    &.__has-sub-title{
      margin-bottom: 30px;
    }

    span{
      color: $dark-blue2;
    }
  }

  .section-sub-title{
    font-family: $DINCBold;
    color: $light-blue;
    font-size: 25px;
    margin-top: 0;
    position: relative;
    padding: 15px 0;
    margin-bottom: 26px;

    @include media(x-small){
      font-size: 30px;
    }

    .line{
      height: 1px;
      background: $dark-blue;
      width: 100%;
      display: block;
      position: absolute;

      &:first-child{
        top: 0;
      }
      &:last-child{
        bottom: 0;
      }
    }

  }

  &.__section-bg{
    color: $light;
    padding: 50px 0;
    margin-bottom: 100px;


    @include media(x-small){
      padding: 85px 0 100px;
    }


    .section-title{
      color: $light;
    }

    .section-sub-title{
      color: $light;

      .line{
        background: $light;
      }
    }
  }

  &.__section-pre-footer{
    padding-top: 60px;
    background: #ececec;

  }

  .bg-image{
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    background-size: cover;
    top: 0;
    overflow: hidden;

    .bg-image2{
      position: absolute;
      left: -50px;
      bottom: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left bottom;
      width: 120%;

      @include media(large){
        width: 60%;
      }
    }
  }
  &.__abs-content{

    >.container{
      position: relative;

      .summary{
        padding-top: 150px;

        @include media(x-small){
          padding-top: 386px;
        }

        @include media(small){
          padding-top: 0;
          margin-top: -100px;
        }

        p{
          margin-bottom: 0;
        }
      }
    }

    .abs{
      position: absolute;
      left:-90px;
      top: -209px;
      z-index: -1;

      @include media(x-small){
          left:-23px;
      }
      @include media(small){
        left: 50%;
        top: -150px;
      }

      img{
        max-width: 100%;

        @include media(x-large){
          max-width: none;
        }

      }



    }

  }






}

.main-content{
  padding: 30px 0;

  @include media(x-small){
    padding: 60px 0;
  }

  @include media(medium){
    padding: 120px 0;
  }

  h2{

    font-size: 30px;
    font-family: $DINCBold;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 0;

    @include media(x-small){
      font-size: 37px;
    }

    @include media(small){
      font-size: 40px;
    }
  }


  h3{
    font-family: $DINCBold;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $light-blue;

    .bar{
      width: 1px;
      height: 40px;
      background: #1286c0;
      display: inline-block;
      vertical-align: middle;
      line-height: 20px;
      position: relative;
      transform: translateY(-8px);
    }

  }
  .footnote{
    font-size: 15px;
    color: $dark-blue2;

    h5{
      font-size: 20px;
      font-family: $DINCBold;
      color: #569EBE;
    }
  }
}



#main{
  font-size: 18px;


  img{
    max-width: 100%;
  }
}

#sidebar{


  @include media(medium){
    padding-left: 50px;
  }

  img{
    max-width: 100%;
  }
  .mod-sidebar-menu{
    background: #27566E;
    padding: 57px 35px;
    font-size: 16px;
    margin-bottom: 80px;





  h3{
    color: $light;
    font-size: 30px;
    font-family: $DINCBold;
    padding-bottom: 25px;
    border-bottom: 1px solid $light;
    margin-bottom: 30px;
  }


  ul.menu{
    list-style: none;
    padding: 0;
    margin: 0;

    li{
      padding: 10px 0;
      a{
        color: $light;
        text-decoration: none;
        position: relative;
        display: block;


        &::before{
          content: "";
          background: url(../images/arrow-svg-lb.svg) no-repeat;
          width: 12px;
          height: 12px;
          display: block;
          position: absolute;
          background-size: contain;
          top: 6px;
          left: -22px;
          opacity: 0;
          visibility: hidden;
          transform: translateX(-10px);
          @include transition(all .3s);
        }

        &:hover{
          color: #569EBE;

          &::before{
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

      &.active{

        a{
          font-weight: 700;
          color: #569EBE;

          &::before{
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

    }
  }

  }


  .mod-widget{
    margin-bottom: 80px;
    padding-bottom: 30px;
    border-bottom: 1px solid #123150;
  }

  .download{
    text-decoration: none;
    font-size: 28px;
    color: $dark-blue2;
  }
}
