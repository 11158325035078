.legend-contents {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
}
.dv-legend {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}
span.dv-indicator {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    padding-right: 10px;
}
span.dv-desc {
    width: 80%;
    display: inline-block;
    padding-right: 10px;
}
.small-td-img {
	width: 40px;
}
.t-bgv30 {
	background-color: #dcdddf;
	border-bottom: 1px solid #fff;
}
.t-bgv30 .th-v1 {
	font-weight: bold;
	margin-bottom: 10px;
	display: inline-block;
}

ol.no-style {
	list-style:none;
}
.home-cont2-box .caption .name,
.home-cont2-box .caption .label {
	font-weight: bold !important;
}




.blue, .blue strong{
 color: #35b1dc;

}
.table-notes{
	display: none;
	margin-bottom: 10px !important;
	color: #777 !important;
}
.blue strong{}
.btn-primary {
    color: #fff;
    background-color: #46b3d9;
    border-color: #46b3d9;
}

.download-box{
	padding:20px;
	text-align: center;
	max-width: 400px;
}
ul, ol{
	margin-bottom: 20px;
}

a.disabled{
  cursor: default !important;
}
a.disabled:hover{
  color: inherit !important;
}
figure.wp-caption{
  max-width: 100%;
}
.subpage-contents h4.this-green, .subpage-contents h5.this-green{
	color: #48773c;
	font-weight: bold;
}





#accordion{
	background-color: #46b3d9 !important;
}

.cdl-nav ul{
	padding: 8px 0 0 0;
	margin-bottom: 8px;
}


.cdl-nav ul li{
	list-style:  none;
	background-position: right 2px top 12px;
	background-size: 8px 12px;
}

.cdl-nav ul li a{
	font-size: 13px;
}


.cdl-nav ul ul{
	position: absolute;
	text-align: left;
	left: 230px;
	margin-top: -34px;
	background-color: #369CC0;
	width: 235px;
	display: none;
	padding-bottom: 6px;

	-webkit-box-shadow: 3px 4px 5px -2px rgba(0,0,0,0.42);
	-moz-box-shadow: 3px 4px 5px -2px rgba(0,0,0,0.42);
	box-shadow: 3px 4px 5px -2px rgba(0,0,0,0.42);

}








.cdl-nav ul li a{
	color: #FFFFFF;
	text-decoration:  none;
	display: block;
	padding: 4px 20px;

	transition: color 0.6s ease;
}






.cdl-nav ul ul li a{
    background-color: #369CC0 !important;
	color: #fff !important;
}

.cdl-nav > ul > li:hover >a,
.touchevents .cdl-nav  ul  li.open > a,
.cdl-nav  ul  li.current-menu-item > a,
.cdl-nav  ul  li.current-menu-ancestor > a,
.cdl-nav  ul ul li.current-menu-ancestor > a,
.cdl-nav  ul  li.current-menu-parent > a{
	color: #ffcd6b;
	background-color: #2D86A5;
}


.no-touchevents .cdl-nav ul ul li a:hover{
	color: #ffcd6b !important;
	background-color: #2D86A5 !important;
}


.cdl-nav ul ul li:hover > a,
.cdl-nav ul ul li.current-menu-ancestor > a,
.cdl-nav ul ul li.current-menu-item > a,
.cdl-nav ul ul li.current-menu-parent > a{
	color: #ffcd6b !important;
	background-color:#237C9B !important;
}

.no-touchevents .cdl-nav ul ul ul li:hover > a{
	color: #ffcd6b !important;
	background-color:#237C9B !important;
}


.third-level{
	display: none !important;
}

.menu-item-has-children.open >a{
  /*background-position: right 6px top 8px;*/
}

.cdl-nav ul li ul li:hover .third-level{
	display: block !important;
}

.dropdown-menu{
  float: none;
  min-width: 0;
  margin-top: 0;
  border:0;
  border-radius: 0;
}
.dropdown-menu > li > a{
  white-space: normal;
}

.fourth-level{
	display: none !important;
}

.cdl-nav ul li ul li ul li:hover .fourth-level{
	display: block !important;
}


.liner2{
	margin-top: -44px !important;
}

.level4 a{
	padding-left: 30px;
}







.cdl-nav-mobile{
	text-align: right;
	display: none;
}

.cdl-nav-mobile a{
	color: #fff;
	font-size: 15px !important;
}

.cdl-nav-mobile .panel-group{
	padding-top: 12px;
	/*border-bottom: 8px solid #32aad3;*/
	margin-bottom: 0px;
	/*background-color: #46b3d9 !important;*/
}


.cdl-nav-mobile .panel{
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
}

.cdl-nav-mobile .panel-heading{
	padding: 3px 18px 3px 6px;
}

.cdl-nav-mobile .panel-heading a{
	display: block;
}

.cdl-nav-mobile .panel-heading a[aria-expanded="true"]{
	color: #ffcd6b;
	text-shadow: 1px 1px 0px rgba(150, 150, 150, 1);
}


.cdl-nav-mobile .panel-default > .panel-heading{
	background: none;
	color: #fff;
}


.cdl-nav-mobile .panel-group .panel{
	border-radius: 0px;
}

.cdl-nav-mobile .panel-group .panel{
	border: none;
	background: none;
	color: #fff;
}

.cdl-nav-mobile .panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group{
	border-top: 1px solid #7BD3F2
}

.cdl-nav-mobile .panel-body{
	padding: 12px 16px 15px 0;
	background-color: #1990b7;
}

.cdl-nav-mobile .panel-body ul li{
	padding: 4px 0;
}


.panel-body ul {
	margin-bottom: 0px;
}



.l4{
	margin-right: 20px;
}

/*END mobile accordion menu temporary solution*/



.cdl-nav .main-l3{
	padding: 5px 0px 15px 0;
	margin: 0 -10px 0 0;

}

 .cdl-nav .main-l3 ul li{
	 background:none;

}

.cdl-nav .main-l3 ul li a{
	color: #ace0f3;
	list-style:  none;
	padding: 8px 0 0 0;
	background-position: right 2px top 12px;
	background-size: 8px 12px;
	padding-right: 12px;
	padding-left: 8px;
}

.cdl-nav .main-l3 ul li a:hover{
	color: #fff;
}


.cdl-nav .main-l3 ul li li a{
	background: none;
}

.search-box{
	padding: 0 19px 0 12px;
}


.cdl-nav a{
	color: #fff;
	text-decoration: none !important;
}

.cdl-nav a:hover{
	color: #ffcd6b;
}

.search-box{
	position: relative;
	background-color: #46b3d9;
}

.dl-box{
	position: relative;
	text-transform: uppercase;
	text-align: right;
	line-height: 16px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	background-color: #2794ba;
	height: auto;
}

.dl-box span{
	display: inline-block;
	position: relative;
	text-align: right;
}

.dl-box a{
	color: #FFFFFF;
	display: block;
	padding: 12px 20px 12px 12px;

	transition: background-color 0.6s ease;

}

.dl-box a:hover{
	background-color: #efa617;
	text-decoration:  none;
	color: #fff;
}


.ssa-box span{
	display: inline-block;
	position: relative;
	text-align: right;
}


.ssa-box a{
	color: #FFFFFF;
	display: block;
	padding: 15px 20px 15px 12px;

	transition: background-color 0.6s ease;

}

.ssa-box a:hover{
	background-color: rgba(148,109,36,0.7);
	text-decoration:  none;
	color: #fff;
}

.e-gen-box{
	position: relative;
	text-align: right;
	text-transform: uppercase;
	background-size: cover;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	opacity: 1;
	line-height: 16px;

	transition: opacity 0.6s ease;
}

.e-gen-box img{
	width: 129px;
	height: 50px;
}

.e-gen-box span{
	display: inline-block;
	position: relative;
	text-align: right;
}


.e-gen-box a{
	color: #FFFFFF;
	display: block;
	padding: 10px 20px 10px 12px;

	transition: background-color 0.6s ease;

}

.e-gen-box a:hover{
	background-color: rgba(206,177,123,0.70);
	text-decoration:  none;
	color: #fff;
}
.e-gen-box.default a{
  padding: 20px 20px 20px 12px;
  background: rgba(0, 108, 179, .7);
}
.e-gen-box.default:hover a{
  background-color: rgba(0, 108, 179, .9);
}




.gf-box{
	position: relative;
	text-align: right;
	text-transform: uppercase;
	background-size: cover;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	opacity: 1;
	line-height: 16px;

	transition: opacity 0.6s ease;
}

.gf-box span{
	display: inline-block;
	position: relative;
	text-align: right;
}


.gf-box a{
	color: #FFFFFF;
	display: block;
	padding: 20px 20px 20px 12px;

	transition: background-color 0.6s ease;

}

.gf-box a:hover{
	background-color: rgba(60,100,12,0.85);
	text-decoration:  none;
	color: #fff;
}

.wfg-box{
	position: relative;
	text-align: right;
	text-transform: uppercase;
	background-size: cover;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	opacity: 1;
	line-height: 16px;

	transition: opacity 0.6s ease;
}

.wfg-box span{
	display: inline-block;
	position: relative;
	text-align: right;
}


.wfg-box a{
	color: #FFFFFF;
	display: block;
	padding: 20px 20px 20px 12px;

	transition: background-color 0.6s ease;

}

.wfg-box a:hover{
	background-color: rgba(100,12,84,0.7);
	text-decoration:  none;
	color: #fff;
}



.dl-vid{
	position: relative;
	text-align: right;
	text-transform: uppercase;
	background-size: cover;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;



}



.dl-vid a{
	display: block;
	padding: 6px;
	color: #FFFFFF;
	padding-top: 20px;
	padding-bottom: 16px;
	padding-right: 14px;

	transition: background-color 0.6s ease;
}

.dl-vid a:hover{
	background-color: rgba(35,97,121,0.8);
	text-decoration:  none;
	color: #fff;


}



.dl-vid a:hover{
	text-decoration: none;
	color: #fff;

}

.dl-vid a img{
	width: 20px;
	height: 20px;
}

.cdl-footer{
	text-align: right;
	position: relative;
	padding: 26px 14px;
	color: #a7a7a7;
	font-size: 11px;
	background-color: #f6f2f2;

}

.cdl-footer a{
	color: #a7a7a7;
	text-decoration: none;
}

.cdl-footer span{
	display: block;
}

.cdl-footer img{
	width: 24px;
	height: 22px;
}


.cdl-container{
	width: 98%;
	position: relative;
	z-index: 97;
	padding-left: 234px;
	margin-top: 4px;
}

/*.cdl-container {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
*/

.cdl-container h2{
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	display: block;
	background-color: #0e5770;
	padding: 8px;
	width: 100%;
}

.home-cont1{
	float: left;
	width: 36%;
	margin-right: 4px;
	color: #fff;
}

.home-cont1 img{
	width: 100%;
	height: auto;
}

.home-cont1 p{
	padding: 4px 10px 4px 10px;
	line-height: 20px;
}

.home-cont1 h1{
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 28px;
	line-height: 31px;
	padding: 20px 10px 8px 10px;

}
.home-cont1 h1 a{
	text-decoration: none;
	color: #fff;
}

.home-cont1 h1 span{
	text-transform: uppercase;
	font-size: 49px;
	line-height: 45px;
	letter-spacing: 1px;
}

.home-cont2{
	float: left;
	width: 33%;
	margin-right: 4px;
}

.home-cont2 a{
	color: #000;
}

.home-cont2 a:hover{
	color: #5A5A5A;
	text-decoration: none;
}

.home-cont2-box{
	float: left;
	width: 100%;
	background-color: #307d98;
}

.sc{
	float: left;
	width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}
.sc:last-child{
  border-bottom:0;
  padding-bottom: 0;
}


.sc a{
	color: #000000;
  display: block;
}

.sc a:hover{
	text-decoration: none;
	color: #333;
}

.sc img{
	width: 100%;
	height: auto;
}

.sc p{
	padding: 8px 8px 0px 8px;
	line-height: 17px;
	margin-bottom: 0px !important;


}

.ssa p{
	padding: 8px 8px 0px 8px;

}


.ssa{
	float: left;
	width: 100%;
	margin-top: 8px;
}

.logo-ssa{
	width: 100%;
	display: block;
	float: left;
	background-color: #f9f9f9;
	margin-bottom: 8px;
	padding-left: 1px;
	border-top: 1px solid #d9d9d9
}

.logo-ssa img{
	width: 100%;
}


.ssa p{
	padding-top: 6px;
}

.the-article{
  width:100%;
	background-color: #307d98;
	float: right;
	color: #fff;
	height: 100%;
	border-bottom: 4px solid #fff;
	position: relative;
}

.the-article .caption{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 40%;
	background: #01b3e1;
	color: #fff;
	padding:5px 10px;
	display: block;

}
.the-article .caption span{
	font-size: 13px;
	display: block !important;
	line-height: 17px;
}
.the-article .caption .name{}
.the-article .caption .label{
	font-style: italic;
	font-weight: normal;
	padding: 0;
    text-align: left;
    font-size: 10px;
}
.the-leader1{
	width:100%;
	background-color: #307d98;
	float: right;
	color: #fff;
	height: 100%;
	border-top: 4px solid #fff;
}

.the-leader2{
	width:100%;
	background-color: #307d98;
	float: right;
	color: #fff;
	height: 100%;
}

.the-leader1 img{
	width: 40%;
	float: left;
	height: auto;
	margin-right: 14px;

}

.the-article img,
.the-leader2 img{
	width: 40%;
	float: left;
	height: auto;
	margin-right: 14px;

}


.the-leader1 a, .the-leader2 a, .the-article a{
	color: #fff;
	text-decoration: none;
	display: block;
	padding: 10px 6px 0px 6px;
	font-size: 13px;
	line-height: 16px;

}

.the-leader1 a:hover, .the-leader2 a:hover, .the-article a:hover{
	color: #efa617;
}







.home-cont3{
	float: left;
	width: 29%;
}

.i-capital{
	width: 100%;
	border-top: 1px solid #d9d9d9;
	float: left;
	padding-top: 0px;
	margin-top: 5px;
	margin-bottom: 5px;
	background-color: #f7f7f7;


}


.i-capital a{
	color: #000;
	text-decoration: none;
	display: block;
}

.i-capital a:hover{
	color: #efa617;
	text-decoration: none;
}


.i-capital a:visited{
	text-decoration: none !important;

}


.i-capital-pic{
	float: left;
	width: 35%;
}

.i-capital-pic img{
	width: 100%;
}

.i-capital-desc{
	float: left;
	width: 65%;
	padding: 0 2%;
	font-size: 12px;
	line-height: 14px;
}

.i-capital-desc strong{
	display: block;
	padding-top: 8px;
}

.i-capital-desc ul{
	margin-left: 1px;
}

.i-capital-desc ul li{
	font-size: 12px;
	list-style: none;
	background-size: 10px 9px;
	padding-left: 12px;

}

.i-capital-arrow{
	float: right !important;
	color: #efa617 !important;
	padding-right: 8px !important;
}

.footer-logos{
	position: relative;
	width: 100%;
	border-bottom: 1px solid #ebebeb;
	border-top: 1px solid #ebebeb;
	margin: 10px 0 30px 0;;
	height: auto;
	text-align: center;
  font-size: 0;
}

.footer-logos img{
	/*width: 400px;
	height: 68px;*/
  display: inline-block;
  vertical-align: middle;
	width: 50%;
}


.this-orange{
	color: #efa617;
}



.sub-container{
	width: 72%;
	margin: 12px 0px 80px 15px;
	float: left;
}

.sub-banner{
	width: 100%;
	height: 180px;
	position: relative;
	background-size: cover !important;
	background-position: center !important;
}

.sub-banner h1,
.sub-banner h2{
	position: absolute;
	bottom: 0px;
	background-color: rgba(24, 129, 210, 0.8);
	display: inline-block;
	padding: 12px 12px 8px 12px;
	text-transform: uppercase;
	font-size: 26px;
	color: #fff;
}
.sub-banner h1 img,
.sub-banner h2 img{
	width: 200px;
	height: 51px;
}



.subpage-contents{
	position: relative;
	width: 100%;
	margin: 0 0 80px 0;

}

.subpage-contents .alpha{
	list-style: lower-alpha;
}

.subpage-contents ul:not(.nav-tabs) li{
	padding-bottom: 8px !important;
  position: relative;
  list-style: none;

  &::before{
    content: "";
    background: url(../images/blueArrowHead.svg) no-repeat;
    width: 17px;
    height: 17px;
    display: block;
    position: absolute;
    left: -25px;
    top: 5px;
  }
}

.subpage-contents img{
	max-width: 100%;
	height: auto;
}

.subpage-contents h1,
.subpage-contents h2{

}


.subpage-contents ul{
	margin: 0 0 20px 20px;
}



.sv{
	font-size: 17px;
	font-weight: 600;
	color: #35b1dc;
	display: block;
	background-size: 26px 27px;
	padding: 7px 0 0 32px;
}

.sv p{
	font-size: 13px;
	color: #000000;
	font-weight: normal;
}

.this-blue{
	color: #35b1dc
}

.subpage-contents h4{
	color: #35b1dc;
	font-size: 16px;
	font-weight: 400;
	margin: 10px 0 10px 0;
}
.subpage-contents h5{
	color: #35b1dc;
	font-size: 16px;
	font-weight: 400;
	padding: 10px 0;
}
.subpage-contents p{
	text-align: justify;
}
.subpage-contents p.post-author{
	text-align: left;
}
.subpage-contents p.text-center{
  text-align: center;
}


.subpage-contents{

  a{
  	color: $light-blue;
  	transition: color 0.6s ease;
  }

  ul li{

    a{
      color: $dark-blue;
    }
  }
}

.subpage-contents a:hover{
	text-decoration: underline;
	color: $dark-blue;

}

.subpage-contents a.has-video{
  display: block;
  outline: 0;
  text-decoration: none;
}
.subpage-contents a.has-video img{
  margin-bottom: 0;
}
.subpage-contents a.has-video span.icon{
  position: absolute;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  z-index: 20;
}
.subpage-contents a.has-video .video-wrapper{
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    height: 250px;
    background-position: top right;
    background-size: cover;
}

@media (min-width:1920px) {
  .subpage-contents a.has-video .video-wrapper{
    height: 300px
  }
}

.subpage-contents a.has-video .video-wrapper span.cover{
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  background: rgba(0, 0, 0, 0.53);
  left: 0;
}
.subpage-contents a.has-video span.icon i{
  font-size: 35px;
  color: #fff;
}
.accord-sub h4{
	font-size: 14px;
	font-weight: 600;
	display: block;
	padding: 12px !important;
	margin: 0 0 6px 0;
	background-color: #f9f8f8 !important;
	border-radius: 2px;

	transition: background-color 0.6s ease;
}

.accord-sub h4:hover{
	background-color: #35b1dc !important;
	color: #fff;
}

.accord-sub h4.ui-state-active{
	background-color: #35b1dc !important;
	color: #fff;
}

.accord-sub h4.ui-accordion-header{
	border-bottom: 1px dotted #CECECE;
	padding: 5px 18px 5px 0;
	background-size: 20px 13px;
}

.accord-sub h4.ui-state-active{
	border-bottom: none !important;
	background-size: 20px 13px;
}

.accord-sub h4.ui-accordion-header{
	outline: 0;
  line-height: 21px;
}

/*Solution for jumpy animation */
.accord-sub .ui-accordion .ui-accordion-content{
      box-sizing:content-box;
      -moz-box-sizing:content-box;
}


.accord-body{
	display: block;
	padding: 12px 20px 0px 20px;
}

.acc-t .accord-body{
	display: block;
	padding: 0px;
	margin: 0px;
}



.border-p2{
	width: 100%;
	border-bottom: 2px solid #feb010;
	display: block;
	clear: both;
	margin-bottom: 20px;
}

.footnotes{
	font-style: italic;
	font-size: 11px;
	color: #939393;
	font-weight: 400;
}

.footnotes td{
	vertical-align: top;
}



.sub-right-menu{
	width: 20%;
	float: left;
	margin-left: 15px;
	margin-top: 269px;
	height: auto;

}

.sub-menu-title{
	display: none;
	color: #fff;
	font-weight: 600;
	padding: 8px;
	border: 1px solid #dedede;
	margin-bottom: 4px;
	background-color: #46b3d9;


}

.sub-right-menu  ul{

	height: auto;
	display: block;
}
.sub-right-menu  >ul{
  position: fixed;
}
.sub-right-menu ul li{
	list-style: none;
	display: block;
}

.sub-right-menu ul  ul{
  margin-left: 10px;
}
.sub-right-menu ul li a{
	font-weight: 700;
	font-size: 12px;
	display: inline-block;
	padding: 8px;
	border: 1px solid #dedede;
	margin-bottom: 4px;
	background-color: #46b3d9;
	color: #fff;
	border-top-left-radius: 8px;
	border-bottom-right-radius: 8px;

	transition: background-color 0.6s ease;
}





.sub-right-menu .level3{
	margin-left: 10px;

}
.sub-right-menu .level3 a,
.sub-right-menu ul ul li a{
	padding: 4px;
	border-top: 0px;
	border-right: 0px;
	font-size: 11px;
	color: #fff;
}

.sub-right-menu .level4{
	margin-left: 20px !important;

}

.sub-right-menu .level4 a{
	padding: 4px;
	border-top: 0px;
	border-right: 0px;
	font-size: 11px;
	color: #fff;
	margin-left: 0px !important;

}


.sub-right-menu ul li >a:hover,
.sub-right-menu ul li.current-menu-item> a{
	text-decoration: none;
	background-color: #eaeaea;
  color: #000000;
}

.sub-right-menu ul li a.active-sub{
	color: #fff;
	background-color: #efa617;
}

.sub-right-menu ul li a.active-sub .level3{
	color: #fff;
	background-color: #9D9D9D;
}

.box-c{
	width: 31%;
	float: left;
	border: 1px solid #efefef;
	border-top: 3px solid #feb010;
	border-bottom: 0;
	margin-right: 12px;
	padding: 0px 12px 12px 12px;
	border-top-right-radius: 30px;
	border-bottom-left-radius: 30px;



}

.box-c p{
	text-align: left;
	margin-bottom: 12px;
}

.box-c ul{
	margin-bottom: 10px;
}

.box-c ul li{
	padding-bottom: 4px;
	padding-top: 4px;
	margin-left: 12px;

}


.pad-top{
	position: relative;
	display: block;
	margin-top: 18px;
}

.v-year{
	font-weight: 700;
	font-size: 16px;
	color: #efa617;
}

/* Back to top*/
 #back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    background: #f5f5f5;
    color: #fff;
    cursor: pointer;

    border-radius: 2px;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
	border-radius: 100%;
	background-color: #8abfd4;
}
#back-to-top:hover {
    background: #efa617;
}
#back-to-top.show {
    opacity: 1;
}

#back-to-top .glyphicon{
	top: 3px;
}

/*tables*/
.cdl-t{
	padding-bottom: 10px;

}
.cdl-t p{
	text-align: left !important;
}


.cdl-t table tr td{
	padding: 9px 10px;
}

.no-gutter, .no-gutter li{
	padding:0 !important;
}
.no-gutter.last{
	padding-bottom: 4px !important;
}
.inner-table td:first-child{
	background: #f9f8f8;
  vertical-align: middle;
}
.no-bg{
	background: 0 !important;
}
.cdl-t table ul li{
	padding-bottom: 10px;
}
.cdl-t table ul.half-gutter li{
	padding-bottom: 5px;
}
.no-link{
	cursor: none;
	pointer-events: none;
	color:  #2c2c2c !important;
}

.cdl-t h4{
	padding-bottom: 0px;
}

.t-head{
	font-size: 18px;
	background-color: #2989ab;
	color: #fff;
  font-family: $DINCBold;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 18px;
}

.t-head2{
	font-weight: 600;
	font-size: 14px;
	border-bottom: 2px solid #35b1dc;
	color: #35b1dc
}

.sdg-h{
	font-weight: 700;
	display: block;
}

.t-bg1{
	background-color: #f9f9f9;
	padding: 20px !important;
}

.t-bg2{
	background-color: #ececec;
	padding: 20px !important;
}

.t-bgv1{
	padding: 0px !important;
	padding-left: 10px !important;
}

.t-bgv1 ul{
	padding: 0px;
	padding-top: 6px;
	margin-bottom: 0px !important;
}

.t-bgv2{
	padding: 0px !important;
	padding-left: 10px !important;
}

.t-bg3{
	background-color: #fff;
	padding: 20px !important;
}
.t-bg3 img{
	width: 35%;
}


.t-bg4{
	background-color: #ececec;
	padding: 8px !important;
}
.t-bg45{
	background-color: #f9f8f8;
	padding: 8px !important;
}

.t-bg5{
	padding: 20px !important;
	border-bottom: 1px solid #D6D6D6;
}

.t-bg6{
	background-color: #dbf3ff;
}

.t-bg7{
	background-color: #dbeef8;
}

.t-bg8{
	background-color: #bfe7fc;
}





.m-issues{
	width: 18px;
	height: 18px;
	border-radius: 100%;
	background-color: #2989ab;
	color: #fff;
	display: inline-block;
	float: left;
	padding: 2px;
	text-align: center;
	margin-right: 8px;
	margin-top: 2px;
	font-size: 11px;
}

.spin{
	display: inline-block;
	margin-top: 3px
}

.box-div2{
	width: 30%;
	float: left;
}

.box-div2.__icon{
  float: none;
  width: auto;
  max-width: 185px;
  padding: 0 20px 0 0;
}
.box-div2 img{
	width: 100%;
}

.this-encap{
	width: 70%;
	float: left;
	font-weight: 700;
	padding: 8px 0 0 10px;
}

.box-c2{
	width: 100%;
	border: 1px solid #e8e8e8;
	padding: 0px 16px 16px 16px;
	margin-bottom: 20px;
	border-top: 2px solid #efa617;
	border-bottom-left-radius: 40px;
	border-top-right-radius: 40px;

}



.box-c3{
	width: 100%;
	border: 1px solid #e8e8e8;
	padding: 16px;
	margin-bottom: 20px;
	border-top: 2px solid #efa617;
	border-bottom-left-radius: 40px;
	border-top-right-radius: 40px;

}

.box-c4{
	width: 100%;
	padding: 16px;
	margin-bottom: 20px;
	border-top: 2px solid #efa617;
	border-bottom-left-radius: 40px;
	border-top-right-radius: 40px;
	border-right: 1px solid #e8e8e8;

}

.sr-dl-l{
	display: inline-block;
	padding: 0px 20px;
	color: #FFFFFF !important;
	background-color: #efa617;
	margin: 10px 12px 0 0;
	font-size: 12px;
	text-align: center;


}

.sr-dl-l:hover{
	background-color: #e8e8e8;
}

.sr-dl{
	font-size: 16px
}

.sr-dl td{
	border-top: none !important;
	border-bottom: 1px dotted #e8e8e8 !important;
}

.sr-dl img{
	width: 130px;
	height: auto;
	border: 3px solid #ECECEC;
	border-top-right-radius: 16px;

}
.sr-dl .lg-banner{
	margin-bottom: 10px;
}
.sr-dl .lg-banner img{
	width: auto;
	width: 600px;
	max-width: 100%;
	border:0;
}

.ssa-v{
	font-size: 24px;
	text-align: right;
	text-align: left !important;
	line-height: 35px !important;
	color: #3C3C3C;
	letter-spacing: -1px;
}


.ssa-logo-intro img{
	width: 180px;
	height: 60px;
}


.support-logo{
	width: 100%;
	float: left;
	margin-top: 20px;
	padding: 12px;
	border: 1px solid #e8e8e8;
}
.ht{
	display: block;
	color: #7E7E7E !important;
	padding: 4px;
	font-size: 14px !important;
	text-align: center;

}

.key-fig-box{
	width: 100%;
	float: left;
	margin-bottom: 20px;

}
.subpage-contents .key-fig-box p{
    text-align: left;
}

.key-fig-box ul{
	width: 100%;
	margin: 0 auto;
}

.key-fig-box ul li{
	list-style: none;
	width: 24%;
	height: 213px;
	float: left;
	margin-right: 1%;
	text-align: left;
	border-bottom: 4px solid #dde5e6;
	border-top: 1px solid #e4e4e4;
	border-radius: 10px;
	border-top-right-radius:  40px;
	border-bottom-left-radius:  40px;
	padding: 12px;
	background-color: #f5fafb;


}

.key-fig-box ul li.long-txt{
	height: 250px;
}

.key-fig-box ul li.long-txt-cg{
	height: 206px;
}

.key-fig-box ul li.long-txt .key-fig-num{
	font-size: 28px;
}

.key-fig-box .b-sr{
	height: 150px;
}


.key-fig-num{
	width: 100%;
	font-weight: 700;
	font-size: 38px;
	position: relative;
	margin-top: 30px;
	letter-spacing: -1px;
	color: #2293ba !important;

}

.key-desc{
	font-size: 14px;
	width: 100%;
	position: relative;
	line-height: 20px;
	display: block;

}

.key-desc-txt-long{
	font-size: 12px;
	line-height: 6px;
	width: 100%;
	position: relative;
	display: block;
	padding-top: 12px;
}

.awards-acc{
	color: #35b1dc;
	font-size: 14px;
	border-right: 1px solid #E5E5E5;
	text-align: right;
}

.news-date{
	color: #efa617;
	font-size: 11px;
	text-align: right;
	font-weight: 600;
}

.news-headline a{
	font-weight: 700;
	font-size: 14px;
	color: #4F4F4F;
}

.news-page{
	text-align: center !important;
	display: block;
	margin-bottom: 20px;
	font-size: 12px;
	letter-spacing: 2px;
}

.sus-in{
	text-align: right;
	font-size: 16px;
}


.cdl-form{
	width: 100%;
	border: 1px solid #EAEAEA;
	border-top: 2px solid #efa617;
	border-top-right-radius: 40px;
	border-bottom-left-radius: 40px;
	padding: 20px;
	background-color: #f5f5f5;
}

.cdl-form input{
	border-radius: 20px;
}

.indent-f{
	padding-left: 30px;
}

.indent-l{
	margin-right: 30px;
}

.plc{
	width: 100%;
	display: block;
	clear: both;
	font-weight: 600;
	margin-bottom: 14px;
}

.chart-image{
	text-align: center !important;
}

.chart-image img{
	max-width: 80%;
	height: auto;
}

.hide-intro{
	display: none;
}

.cal-ev{
	display: block;
	padding: 12px 4px 30px 4px;
	background-color: #c19642;
	color: #fff;
	text-align: right;
	border-top-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.cal-ev-green{
	background-color: #1dce65;
}

.cal-ev-pink{
	background-color: #ce34ce;
}

.cal-ev-blue{
	background-color: #3dcdf1;
}
.cal-ev-red{
	background-color: #ea2f32;
}
.cal-ev-yellow{
	background-color: #D7CC27;
}

.this-bold{
	font-weight: 700;
}

/* Google search */
.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn{
	font-size: 14px !important;
	height: 27px;
	padding: 0 20px 0 0;
}

.input-group .form-control{
	margin: 0 0 12px 12px;
	float: right;
}

.form-control{
	height: auto;
}


.ch-pic{
	border-bottom: 4px solid #35b1dc;
	display: block;
	margin-bottom: 20px;

  ~ h2{
    color: $light-blue;
  }
}


.ch-pic img{
	max-width: 80%;
}

.excerpt{
  font-weight: 600;
  font-size: 17px;
  background-color: #f5f5f5;
  padding: 12px;
  line-height: 24px;
  width: 100%;
  display: block;
  margin-bottom: 30px;
}

.spotlight{
	font-weight: 400;
	font-size: 14px;
    background-color: #f5f5f5;
	padding: 12px;
	line-height: 30px;
	width: 100%;
	display: block;
	margin-bottom: 30px;
	border-top-left-radius: 18px;
	border-bottom-right-radius: 18px;
}

.this-arial{
	font-family:  'Helvetica Neue', Helvetica, sans-serif;
}


.no-bottom-margin{
	margin-bottom: 0px !important;
}

.sdg-sm img{
	width: 30px;
	height: 25px;
}

.this-small-f{
	font-size: 14px;
	color: #000;
}

.c-policy{
	font-weight: 600;
	font-size: 14px;
}

.risk-opp{
	width: 100%;
	text-align: center;
	display: block;
}

.risk-opp img{
	width: 200px;
	height: 203px;
}

.r-o h4{
	text-align: center;
	border-top-left-radius: 18px;
	border-bottom-right-radius: 18px;

}

.r-o .risk-head{
	background-color: #767676 !important;
	color: #F9F9F9;
}


.r-o .opp-head{
	background-color: #36b9f2 !important;
	color: #F9F9F9;
}

.nc-t table tr{
	border-bottom: 1px solid #DCDCDC !important;

}

.nc-t table tr table tr{
	border: 0 !important;
}
.nc-t table tr table tr td:last-child{

}
.nc-t table tr table tr td:first-child{

}
.nc-t ul{
	margin-bottom: 0px !important;
}



.nc-k table tr{
	border-bottom: 1px solid #DCDCDC !important;

}

.nc-k ul{
	margin-bottom: 0px !important;
}

.nc-k p{
	padding: 0px !important;
	margin: 0px;
}

.this-un{
	text-decoration: underline;
}

.this-c{
	text-align: center;
}

.chart-s img{
	width: 80%;
	margin-top: 16px;
}


.b-bottom{
	border-bottom: 1px solid #ddd !important;
}

.n-date{
	font-size: 11px;
	color: #7C7C7C;
}

.n-teaser{
	font-size: 15px;
	font-weight: 600;
}

.in-pic img{
	width: 100px;
	height: 100px;

}

.in-pic h3{
	margin-bottom: 4px;
}

.this-g{
	color: #4A4A4A;
}

.f-b{
	font-size: 14px;
	font-weight: bold;
}

.best-viewed{
	display: block;
	color: #a7a7a7;
	padding-top: 8px;
}




.b-100{
	font-weight: 700;
	font-size: 24px;
	color: #2293ba;
}

.hov-o:hover{
	color: #efa617;
}

.news-csr ul li{
	background: none;
}





.main-tabs a{
	border-radius: 20px 4px 20px 0 !important;
	background-color: #46b3d9;
	color: #fff;
	padding: 8px 50px !important ;

}


.main-tabs .active a{
	color: #fff !important;
	background-color: #efa617 !important;
}

.main-tabs{
	border-bottom: 0px !important
}

.k-fig-med{
	font-size: 16px;

}

.k-fig-black{
	color: #000;
}


.dual-chart img{
	width: 50%;
	float: left
}



.share-this{
	display: block;
	clear: both;
	text-align: right;
	margin-bottom: 16px;
	margin-top: -10px;
	float: right;
	width: 100%;
	padding-right: 50px;

}

.share-icon img{
	width: 24px;
	height: 22px;
}

.share-icon{
	display: inline-block;
	float: right;
	margin-right: 6px;
	border-radius: 100%;
	cursor: pointer;
}

.share-s{
	display: inline-block;
	float: right;
	margin: 4px 6px 0 0;
	font-weight: 600;
	font-size: 11px;
}

.e-gen {
	background-color: rgba(244,237,224,0.94) !important;
}

.e-gen img{
	width: 246px !important;
	height: 95px !important;
}

.curve-this{
	border: 1px solid #f4ede0;
	border-top-left-radius: 50px;
	border-bottom-right-radius: 50px;
	margin-top: -10px;
}

.curve-this02{
	border-top-left-radius: 50px;
	border-bottom-right-radius: 50px;
}


.a-right{
	text-align: right;
	display: block;
	font-weight: 700;
	margin-bottom: 30px;
}

.wfg-amb img{
	width: 130px;
	height: 130px;
	margin-bottom: 14px;
	border-top-left-radius: 40px;
	border-bottom-right-radius: 40px;
	border: 1px solid #E1E1E1;
}

.wfg-amb a{
	color: #000000;
	font-weight: 600;
}

.wfg-amb-box1{
	width: 100%;
	margin-bottom: 20px;
	float: left;
}

.wfg-amb-box{
	width: 33%;
	display: inline-block;
  vertical-align: top;
	text-align: center;
}

.wfg-qp{
	width: 18%;
	float: left;
	margin-right: 2%;
	text-align: right;
}

.wfg-qp img{
	width: 120px;
	height: 120px;
	height: auto;
    margin-bottom: 14px;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border: 1px solid #E1E1E1;
}

.wfg-qq{
	width: 78%;
	float: left;
}
.wfg-qq.full{
  width: 100%;
}

.wfg-amb-n{
	font-size: 15px;
}

.wfg-amb-t{
	font-size: 12px;
	font-style: italic;
	font-weight: 600;
}

.ssa-vid{
	float: left;
	width: 100%;
	margin-bottom: 30px;
}

.ssa-vid-box{
	width: 49%;
	float: left;
	margin-right: 1%;
}

.ssa-vid-box img{
	width:100%;
	margin-bottom: 8px;

}

.ssa-gov{
	width: 100%;
	float: left;
}

.ssa-gov h4{
	font-weight: 600;
	margin-bottom: 0px;
	font-size: 14px;
	margin-left: 4px;
}

.ssa-gov a{
	display: block;
}

.ssa-gov-b{
	width: 48%;
	float: left;
	margin-right: 1%;
}

.gov-ind{
	width: 100%;
	background-color: #ECECEC;
	padding: 10px;
	margin: 4px;
	border-top: 1px solid rgba(226,226,226,1.00);
}

.ssa-gov-t{
	color: #000000;
	font-size: 13px !important;
}


.wfg-prof-pic{



}

.wfg-prof-bio{



}

.sm-banner img{
	width: 100%;
	border: 1px solid #e5e5e5;
}


/**
 * 8.0 - Alignments
 */

.alignleft {
	float: left;
	margin: 0.375em 1.75em 1.75em 0;
}

.alignright {
	float: right;
	margin: 0.375em 0 1.75em 1.75em;
}

.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto 1.75em;
}

blockquote.alignleft {
	margin: 0.3157894737em 1.4736842105em 1.473684211em 0;
}

blockquote.alignright {
	margin: 0.3157894737em 0 1.473684211em 1.4736842105em;
}

blockquote.aligncenter {
	margin-bottom: 1.473684211em;
}

.author.author-blog{
	background-color: #46b3d9;
    margin: 0 -19px;
    padding: 20px;
    color: #fff;
}
.author.author-blog p{
	margin-bottom: 10px;
}
.author.author-blog img{
	border: 2px solid #fff;
}

/*Pagination*/
.pagination-lg > li > a, .pagination-lg > li > span{
  font-size: 14px;
}
.pagination > li > a, .pagination > li > span{
  color: #35b1dc;
}

/*Calendar*/
#tribe-events-content{}
#tribe-events-content a{

}
.tribe-events-list-separator-month{
  font-size: 20px;
}
.tribe-events-list .type-tribe_events h2{
  font-size: 14px;
}
.tribe-events-calendar td.tribe-events-past .tribe-events-month-event-title a, .tribe-events-calendar td.tribe-events-past .tribe-events-viewmore a,
.tribe-events-othermonth .tribe-events-month-event-title, .tribe-events-othermonth div[id*=tribe-events-daynum-],
.tribe-events-calendar td.tribe-events-past .tribe-events-month-event-title a, .tribe-events-calendar td.tribe-events-past .tribe-events-viewmore a{
  opacity: 1;
  -khtml-opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
}
.tribe-events-calendar thead th{
  background-color: #FF9800;
}
.tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-], .tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-]>a{
  /*background-color: rgb(148, 148, 148);*/
}
#tribe-events-content .tribe-events-calendar div[id*=tribe-events-event-] h3.tribe-events-month-event-title{
  padding: 5%;
}
#tribe-events-content .tribe-events-calendar td{
  background: rgba(255, 193, 7, 0.1);
}
#tribe-events-content .tribe-events-calendar div[id*=tribe-events-event-] h3.tribe-events-month-event-title{
  font-size: 14px;
}
.tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-], .tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-]>a{
      background-color: #b2b2b2;
}
.single .tribe-events-before-html{
  display: none;
}
.tribe-events-calendar thead th{
  border-left: 1px solid #c37400;
  border-right: 1px solid #c37400;
}
 .tribe-events-schedule{
  display: none;
}
.tribe-events-cal-links{
  text-align: right;
  margin-top: 1.75em;
}
a.tribe-events-gcal, a.tribe-events-ical{
  float: none;
  margin-top: 0;
}
.tribe-events-list .tribe-events-venue-details,
.tribe-events-tooltip .tribe-events-event-body .tribe-event-date-start,
.tribe-events-meta-group-details dl dt:nth-child(3),
.tribe-events-start-time.published.dtstart{
  display: none;
}
.events-archive.events-gridview #tribe-events-content table .type-tribe_events{
      padding: 6px 0px;
}
.tribe-events-event-schedule-details{
  margin-right: 0;
}
.tribe-events-tooltip{
  padding: 20px !important;
}
/*Social Media*/
.social-media, .subpage-contents ul.social-media{
  list-style: none;
  padding: 5px 0;
  margin: 0;
  float: right;

  li{

   &::before{
     display: none;
   }
  }
}
.social-media >li{
  display: inline-block;
  margin-right: 6px;
  color: #2c2c2c;
  padding: 0 !important;
  font-weight: 400;
}
.social-media >li >a{
  font-size: 15px;
  width: 24px;
  height: 22px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  border-radius: 50%;
}



/*--------------------------------------------------------------
16.1 Galleries
--------------------------------------------------------------*/
.gallery{
  text-align: center;
}
.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	margin: 0 0 1.5em;
	padding: 0 1em 0 0;
	/*width: 50%;*/
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
	display: inline-block;
	max-width: 100%;
}

.gallery-item a img {
	display: block;
	-webkit-transition: -webkit-filter 0.2s ease-in;
	transition: -webkit-filter 0.2s ease-in;
	transition: filter 0.2s ease-in;
	transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.gallery-item a:hover img,
.gallery-item a:focus img {
	-webkit-filter: opacity(60%);
	filter: opacity(60%);
}

.gallery-caption {
	display: block;
	text-align: left;
	padding: 0 10px 0 0;
	margin-bottom: 0;
}
.social-feed-element .media-object {
	/*margin-top: -4px;*/
}
.social-feed >div{
	width: 100% !important;
	max-width: 100% !important;
}
.social-feed >div .grid-item{
	margin-bottom: 13px;
	width: calc(50% - 10px);
	position: absolute !important;
}
.social-feed-container-1371 .fa-facebook,
.social-feed-container-1371 .fa-instagram,
.social-feed-container-1371 .fa-twitter {
    top: 13px !important;
    right: 13px !important;
}
.social-feed-element .content {
	padding-top: 20px;
	padding-bottom: 20px;
}
.social-feed >div .grid-item .social-feed-element {
	margin: 13px !important;
    width: auto !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}
.post_draft {
	background-color: #ffd8d4;
}
.post_draft .post-edit-link {
	font-size: 14px;
}
.page-template-achievements-accolades .cdl-t .post-edit-link {
	font-size: 13px;
	margin-top: 10px;
	display: block;
}
.accord-sub h4.h4-review {
	background-color: #ffd8d4 !important;
}
.accord-sub h4.h4-review:hover {
	background-color: #ffd8d4 !important;
}
.accord-sub .span-review a.post-edit-link {
    float: right;
    margin-right: 30px;
    margin-top: 25px;
}
#social-feed-container-1371 {
	margin:0 -13px !important;
}
.social-media-ssa {
	margin-top: -15px;
	display: inline-block;
}
.ul-ssa {
	width: 100%;
}
.ul-ssa li {
	float: left;
	margin-left: 8px;
}
html .social-media-ssa .ul-ssa li a {
	font-weight: 700;
    font-size: 30px;
    display: inline-block;
    padding: 0px;
    border: none;
    margin-bottom: 4px;
    background-color: transparent;
    color: #46b3d9;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: background-color 0.6s ease;
}
html .social-media-ssa .ul-ssa li.instagram-link a {
	margin-right: 3px;
}
html .social-media-ssa .ul-ssa li.twitter-link a:hover {
	color: #1da1f2;
}
html .social-media-ssa .ul-ssa li.instagram-link a:hover {
	color: #e6204e;
}
html .social-media-ssa .ul-ssa li.facebook-link a:hover {
	color: #3a589e;
}
@media (max-width: 767px){
	.social-feed >div .grid-item {
		width: 100%;
	}
}
body.world-green-building-week-2017 .subpage-contents {
	margin-top: 0px;
}
.content-wgbw {
	margin-top: 30px;
}
.content-wgbw .left-content {
	padding-right: 20px;
	float: left;
	width: 75%;
}
.content-wgbw .right-content {
	width: 25%;
}
.content-wgbw .right-content iframe {
	float: right;
	margin-top: -8px;
}
.instruction-content {
    border: 2px solid #efa617;
    border-top: 2px solid #efa617;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 20px;
}
.instruction-content ol {
	margin-bottom: 0px;
}
.instruction-content {
	margin-bottom: 30px;
}
.bottomform-content {
	margin-left: -15px;
}
.a-pdf {
	text-decoration: underline !important;
	color: #70ad47 !important;
}
.a-pdf:hover {
	text-decoration: underline !important;
	color: #70ad47 !important;
}
.div-pw form {
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    color: #70ad47
}
label[for="pwbox-1439"] {
	margin-top: 20px;
	display: block;
	color: #000;
}
.div-pw input[type="submit"] {
	    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
        color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
        border-radius: 20px;

    -webkit-appearance: button;
    cursor: pointer;
}
.div-pw #pwbox-1439 {
    width: 250px;
    display: block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border-radius: 20px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
}
.extra-banner {
	margin-top: 5px;
}
.home-cont1 ul li {
	cursor: pointer;
}
.home-cont1 .home-headbanner {
	overflow: hidden;
}
.home-cont1 .home-headcontent {
	background-color: #2b718a;
	/*margin-top: 4px;*/
}
.fullwidthbanner-container .fullwidthabanner {
	width: 103%;
    left: -10px;
}
.hermes .tp-bullet {
    width: 10px;
    height: 10px;
}


table.table-data tr.year{
	/*display: none;
	opacity: 0;
	transform: translateY(50px);
	transition: all 0.3s;*/
}

table.table-data tr.year-active{
	/*display: table-row;
	opacity: 1;
	transform: translateY(0);*/

}
.select-year{
	position: relative;
	float: left;
	padding-bottom: 20px;
}
.select-year::after{
	clear: both;
	display: block;
	content: "";
}
.select-year label{
	display: inline-block;
	vertical-align: middle;
	margin:0;
	padding-right: 10px;
}
.select-year span{
	display: inline-block;
	border:1px solid #46b3d9;
	border-radius: 4px;
	padding: 5px 10px;
	vertical-align: middle;
	cursor: pointer;
	min-width: 80px;
	transition: all 0.3s;
    position: relative;
}
.select-year span::after{
	content: "\f107";
	display: block;
	position: absolute;
	right: 6px;
	top: 50%;
	-moz-transform: translateY(-50%) rotate(0deg);
	-webkit-transform: translateY(-50%) rotate(0deg);
	transform: translateY(-50%) rotate(0deg);
	    font-family: FontAwesome;
    font-size: 18px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.select-year ul{
	margin:0;
	padding: 0;
	list-style: none;
	position: absolute;
	left: 0;
	top: 10px;
	width: 100%;
	background: #fff;
	visibility: hidden;
	opacity: 0;
	-moz-transition: opacity 0.3s,transform 0.3s;
	-webkit-transition: opacity 0.3s,transform 0.3s;
	transition: opacity 0.3s,transform 0.3s;
	border-radius: 4px;

	border: 1px solid #46b3d9;
	transform: translateY(0);
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);

	box-shadow: 1px 4px 7px #c3c3c3;
	-moz-box-shadow: 1px 4px 7px #c3c3c3;
	-webkit-box-shadow: 1px 4px 7px #c3c3c3;
	z-index: 50;
}
.select-year ul::before{
	content: "";
	border: 6px solid transparent;
	border-bottom-color: #46b3d9;
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	right: 6px;
	top: -12px;
}
.select-year:hover ul{
	visibility: visible;
	opacity: 1;
	-moz-transform: translateY(30px);
	-webkit-transform: translateY(30px);
	transform: translateY(30px);
}
.select-year:hover span{
	box-shadow: 0px 0px 6px rgba(70, 179, 217, 0.52);
	-moz-box-shadow: 0px 0px 6px rgba(70, 179, 217, 0.52);
	-webkit-box-shadow: 0px 0px 6px rgba(70, 179, 217, 0.52);
}
.select-year ul li.active, .select-year ul li:hover{
	background: #46b3d9;
	color: #fff;
}
.select-year ul li{
	border-bottom: 1px solid #46b3d9;
	padding: 3px 10px;
	margin: 0;
	cursor: pointer;

	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.select-year ul li:last-child{
	border:0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.select-year ul li:first-child{
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.img-content {
	text-align: center;
	margin: 0 auto;
	width: 100%;
}
.p-img img {
	width: 100%;
}
.p-img {
	text-align: center !important;
}
.p-content {
	font-size: 11px !important;
}
.h5-date {
	padding-top: 0px !important;
}
.img-content-small {
	padding-left: 0px;
}

.winners{
	margin: 0 !important;
	list-style: none;
	padding-bottom: 20px !important;
}
.winners > li{
	border-bottom:1px solid #f7f1e7;
	padding:25px 0 !important;
}
.winners > li:last-child{
	border:0;
}
.winners .profile{
	margin-bottom: 25px;
}

.winners .profile::after{
	content: "";
	display: block;
	clear: both;
}
.winners .labels{
	float: left;
	padding-top: 10px;
}
.winners .photo{
	height: 131px;
	width: 131px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	float: left;
	margin-right: 15px;
}
.winners .photo img{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	max-width: 100%;
}
.winners .profile.profile_1757 .photo img{
  max-width: 124%;
}
.winners .profile h4{
	display: inline-block;
	color: #fff;
	padding: 10px 13px;
	margin:4px 0px;
	font-weight: bold;
	font-size: 12px;
}

.subpage-contents  .list-inline-centered{
  margin: 0 0 20px 0;
  list-style: none;
  display: inline-block;

}

.subpage-contents  .list-inline-centered li{
  float: left;
  padding: 4px;
}

.subpage-contents  .list-inline-centered::after{
  clear: both;
  content: "";
  display: block;
}

@media(min-width: 1400px){
	.winners .profile h4{
		font-size: 18px;
	}
	.winners .photo{
		margin-right: 33px;
	}
}
.winners .profile .name{
	background: #46b3d9;
}
.winners .profile .title{
	background: #f6b100;
	margin-left: 13px;
}

.winners .btn{
	border:1px solid #46b3d9;
	text-transform: uppercase;
	border-radius: 0;
	font-size: 12px;
	line-height: 1.2;
	padding: 7px 14px 5px;
	margin-top: 5px;
	display: inline-block !important;
	width: auto !important;
	font-weight: bold;
}

.winners.winners-2-cols{}
.winners.winners-2-cols li{
	float:left;
	width: 50%;
	padding-right: 20px !important;
}
.winners.winners-2-cols li:nth-child(3n+2){
	padding-left: 20px !important;
	padding-right: 0 !important;
}

.winners.winners-2-cols li{
	border-bottom:0;
}
.winners.winners-2-cols li.border{
	width: 100%;
	float: none;
	background: #f7f1e7;
	height: 1px;
	clear: both;
	padding:0 !important;
	margin:0 !important;
}
.winners.winners-2-cols li:last-child{
	background: 0 !important;
}
.winner-cat{
	background: #bcbcbc;
	color: #fff;
	text-align: center;
	font-size: 18px;
	padding:8px 0;
	margin-bottom: 15px;
	font-weight: bold;
}

.left_col,
.right_col {
	border: 1px solid #006633;
	border-radius: 10px;
	width: 48%;
	float: left;
	padding: 15px;
}
.center_col {
	width: 4%;
	float: left;
}
.left_col,
.right_col,
.center_col {
    position: relative;
    min-height: 1px;
}
.news_list {
	margin-top: 10px;
	margin-bottom: 20px;
	display: inline-block;
}
.sponsors_list .col {
	display: table-cell;
	width: 20%;
}
.sponsors_list .col img {
	margin: 0 auto;
}
.sponsors_list .row_list {
    display: table;
    width: 100%;
}
.date_head {
	margin-bottom: 15px;
}
.row.osb {
	margin-bottom: 20px;
	margin-top: 30px;
}
.date_head img {
	height: 27px;
}
.ec-contents {
	line-height: 20px;
}
body.eco-bank .subpage-contents {
	margin-top: 0px;
}

.special-boxed{
  text-align: center;
}
.special-boxed table{
  margin-bottom: 20px;
}
.special-boxed strong,
.special-boxed h3{
  color: #70ad47;
}
.special-boxed table th,
.special-boxed table td{
  padding: 5px;
}
.special-boxed .boxed-inside{
  border: 1px solid #70ad47;
  display: inline-block;
  padding: 10px 20px;
  box-shadow: 0px 0px 4px #c5c5c5;
  margin-bottom: 20px;
}
.subpage-contents .special-logos h4{
  margin: 20px 0;
  font-weight: bold;
  color: #000;
}
.subpage-contents .special-logos ul{
  list-style: none;
  margin: 0;
}

.subpage-contents .special-logos ul li{
  display: block;
}

.subpage-contents .special-logos ul li img{
  width: auto;
  max-width: none;
  height: 53px;
}
.subpage-contents .special-logos ul li img.sm-height{
  height: 35px;
}
.subpage-contents .special-logos ul li img.auto-width{
  height: auto;
  width: 100%;
}
.subpage-contents .special-logos ul li img.auto-height{
  height: auto;
}
.subpage-contents .special-logos ul.percentage li{
  float: left;

}
.subpage-contents .special-logos ul.percentage::after{
  clear: both;
  content: "";
  display: block;
}
.subpage-contents .special-logos  ul.percentage li img{
  padding: 2px;
}
@media(min-width: 1200px){
	.winners.winners-2-cols .labels{
		margin-top: 40px;
	}
}
@media( max-width: 1199px){
	.winners .photo{
		width: 107px;
		height: 107px;
		margin-right: 15px;
	}
	.winners .profile h4{
		font-size: 12px;
	}
}

@media(max-width: 767px){
	.winners.winners-2-cols li{
		width: 100%;
		border-bottom: 1px solid #f7f1e7;
	}

  .subpage-contents .special-logos ul li{
    display: inline-block;
    padding-right: 6px;
    vertical-align: middle;
    float: none;
  }

	.winners.winners-2-cols li:nth-child(3n+2){
		padding-left: 0 !important;
    padding-right: 0 !important;
	}

	.winners.winners-2-cols li.border{
		display: none;
	}
	figure.image{
		  background: whitesmoke;
	}
	figure figcaption{
		padding: 10px;
	}
}

figure.image{
	padding:20px 0;
}

figure figcaption{
	padding-top: 10px;
    font-size: 11px;
}

@media (max-width: 1577px){
	.the-article .caption,
	.the-article img, .the-leader2 img{
		width: 57%;
	}
}


@media (max-width: 1400px){
	.the-article .caption{
		padding: 5px;
	}
}




@media (max-width: 1218px){

	.the-article .caption .label{
		font-size: 9px;
	}
}

@media (max-width: 768px){
	.the-article .caption span{
		font-size: 15px;
	}
	.the-article .caption .label{
		font-size: 12px;
	}
}

@media (max-width:767px){
  body.women4green-network .sub-banner.banner-code02{
      background-size: contain !important;
      background-color: #effdee !important;
  }
}
@media (max-width: 667px){
 .the-article .caption span{
line-height: 17px;
    font-size: 13px;
 }
 .the-article .caption .label{
 	font-size: 10px;
 }

}
@media (max-width: 414px){
	.the-article .caption span{
		font-size: 11px;
		line-height: 13px;
	}

	.the-article .caption .label{
		font-size: 8px;
	}
}

@media (max-width: 320px){
	.the-article .caption .label{
		font-size: 7px;
	}
}


@media (max-width: 479px) {
	.ssa-vid{
		float: none;
	}
	.ssa-vid .ssa-vid-box{
		width: 100%;
		float: none;
	}
	.table-notes{
		display: block;
	}
}

@media (max-width: 767px) {
	.the-leader2 a p, .the-leader1 a p, .the-article a p{
		line-height: 20px;
	}
	.left_col, .right_col, .center_col {
		width: 100%;
	}
	.left_col,
	.right_col {
		margin-bottom: 20px;
	}
	.osb div {
		text-align: center !important;
	}
	.sponsors_list .col {
		width: 100%;
		display: block;
    	margin-bottom: 20px;
	}

	.cdl-t{
		  min-height: .01%;
    	overflow-x: auto;
		width: 100%;
	    margin-bottom: 15px;
	    overflow-y: hidden;
	    -ms-overflow-style: -ms-autohiding-scrollbar;
	    border: 1px solid #ddd;
			padding-bottom: 0;
	}
	.cdl-t > table{
		margin-bottom: 0;
	}
	img.alignright,
	img.alignleft{
		float: none;
		margin-left: 0;
		margin-right: 0;
	}

}

@media (max-width: 414px) {
	.content-wgbw .left-content {
	    width: 100%;
	}
	.content-wgbw .right-content {
		width: 100%;
		text-align: center;
	}
	.content-wgbw .right-content iframe {
		float: none;
		text-align: center;
		height: 500px;
		margin-top: 0px;
	}
	.cdl-t.nc-t {
		font-size: 12px !important;
	}
	.cdl-t.nc-t .t-head {
		font-size: 12px;
	}
	.cdl-t.nc-t .t-head .inner-table tr td:last-child {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.cdl-t.nc-t .t-head td:last-child {
		padding-right: 0px;
	}
	.legend-contents .dv-legend {
		width: 100%;
		margin-bottom: 20px;
	}
	.legend-contents .dv-legend .dv-indicator img {
		width: 80%;
	}
}

.fancybox-can-drag .fancybox-image-wrap{


}
